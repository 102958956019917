import { HttpMethods, RestRequest, RestResponse } from '@maverick/http';
import { OloClient } from './client';

interface GetRestaurantMenuParams {
	restaurantId: number;
}

interface GetProductOptionsParams {
	productId: number;
}

interface AddSingleProductToBasketParams {
	basketId: string;
	productid: number;
	quantity: number;
	options: Array<string>;
	specialinstructions: string;
	recipient: string;
}

interface UpdateSingleProductToBasketParams {
	basketId: string;
	basketProductId: number;
	productid: number;
	quantity: number;
	options: Array<string>;
	specialinstructions: string;
	recipient: string;
}

export const MenuService = {
	GetRestaurantMenu: async (params: GetRestaurantMenuParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/v1.1/restaurants/${params.restaurantId}/menu`,
		});
		return await OloClient.ExecuteRequest(request);
	},

	GetProductOptions: async (params: GetProductOptionsParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `v1.1/products/${params.productId}/modifiers`,
			retry: true,
		});
		return await OloClient.ExecuteRequest(request);
	},

	AddSingleProductToBasket: async (params: AddSingleProductToBasketParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `v1.1/baskets/${params.basketId}/products`,
			body: { ...params, options: params.options.join() },
		});
		return await OloClient.ExecuteRequest(request);
	},

	UpdateSingleProductToBasket: async (params: UpdateSingleProductToBasketParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `v1.1/baskets/${params.basketId}/products/${params.basketProductId}`,
			body: { ...params, options: params.options.join() },
		});
		return await OloClient.ExecuteRequest(request);
	},

	AddProductToBasketByChainId: async (params: any): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `v1.1/baskets/${params.basketId}/products/bychainid`,
			body: { ...params },
		});
		return await OloClient.ExecuteRequest(request);
	},

	SaveFavoriteProduct: async (params: any): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `v1.1/users/${params.authToken}/savedproducts`,
			body: params.body,
		});
		return await OloClient.ExecuteRequest(request);
	},

	DeleteFavoriteProduct: async (params: any): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `v1.1/users/${params.authToken}/savedproducts/${params.savedProductId}`,
		});
		return await OloClient.ExecuteRequest(request);
	},

	RetrieveFavoriteProduct: async (params: any): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `v1.1/users/${params.authToken}/savedproducts`,
		});
		return await OloClient.ExecuteRequest(request);
	},
} as const;
