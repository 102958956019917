import { Button, Icon } from '@maverick/ui';
import { FC } from 'react';
import { useRedirect } from '../../hooks';
import * as Styled from './NutritionalInformation.styled';
import { useTheme } from 'styled-components';
import { IconArrowRight } from '@maverick/icons/dist/IconArrowRight';

interface NutritionalInformationProps {
	productName: string;
}

export const NutritionalInformation: FC<NutritionalInformationProps> = ({ productName }) => {
	const theme = useTheme();
	const redirect = useRedirect();

	const nutritionalInformationIndex = productName.split('*').length - 1;

	const redirectToAdditionalInfo = () => {
		redirect(theme.orderUi.links.nutritionAdditionalInfo, { newTab: true });
	};

	return (
		<Styled.Container data-testid='info'>
			<Styled.NutritionalInformation>
				<Styled.Information>
					<Styled.InfoSubtitle data-testid='info-subtitle'>Nutritional information</Styled.InfoSubtitle>

					{nutritionalInformationIndex === 1 && (
						<Styled.InfoDescription data-testid='info-description'>
							*These items are cooked to order. Consuming raw or undercooked meats, poultry, seafood,
							shellfish, or eggs which may contain harmful bacteria may increase your risk of foodborne
							illness, especially if you have certain medical conditions.
						</Styled.InfoDescription>
					)}
					{nutritionalInformationIndex === 2 && (
						<Styled.InfoDescription data-testid='info-second-description'>
							**Item contains or may contain nuts.
						</Styled.InfoDescription>
					)}

					<Styled.InfoDescription data-testid='info-third-description'>
						2,000 calories a day is used for general nutrition advice, but calorie needs vary.
					</Styled.InfoDescription>
				</Styled.Information>

				<Styled.Line />

				<Styled.ButtonAdditional>
					<Button
						text='Additional nutrition information'
						variant='terciary'
						onClick={redirectToAdditionalInfo}
						id={'aditional-nutrition'}
						variantSize='buttonMedium'
					/>

					<Styled.IconArrow onClick={redirectToAdditionalInfo}>
						<Icon icon={IconArrowRight} customSize />
					</Styled.IconArrow>
				</Styled.ButtonAdditional>
			</Styled.NutritionalInformation>
		</Styled.Container>
	);
};
