import styled, { css } from 'styled-components';

import { SelectItemProps } from './SelectItem';
import { TABLET_BREAKPOINT } from '@maverick/utils';

export const Container = styled.div<Pick<SelectItemProps, 'selected' | 'allowDelivery' | 'label'>>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: 2px solid ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	width: 100%;
	border-radius: 4px;
	padding: 8px 0;
	gap: 8px;

	@media (min-width: ${TABLET_BREAKPOINT}px) {
		gap: 16px;
	}

	${({ selected, allowDelivery, label }) =>
		!allowDelivery && label === 'Delivery'
			? css`
					border: #d4d4d4;
					background-color: #d4d4d4;

					${Icon} {
						color: white;
					}
			  `
			: selected &&
			  css`
					border: none;
					background-color: ${({ theme }) => theme.orderUi.colors.primary.variant};

					${Icon} {
						color: ${({ theme }) => theme.orderUi.colors.neutral.light};
					}
			  `};
`;

export const Icon = styled.div`
	width: 40px;
	height: 40px;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Title = styled.div<{ selected: boolean; allowDelivery: boolean }>`
	${({ theme }) => theme.orderUi.typography.headline.h5}
	color: ${({ theme, selected, allowDelivery }) =>
		!allowDelivery ? 'white' : selected ? theme.orderUi.colors.neutral.light : theme.orderUi.colors.tertiary.dark};

	@media (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h4Variant}
	}
`;
