import styled, { css } from 'styled-components';
import { RewardsButtonVariants } from './RewardsButton';

export const Container = styled.div`
	margin-bottom: 16px;
`;

export const Rewards = styled.div<{ variant: RewardsButtonVariants; selected: boolean; disabledCard: boolean }>`
	display: grid;
	grid-template-columns: 1fr 0fr;
	grid-gap: 16px;
	padding: 8px 16px;
	border: 1px solid ${({ theme }) => theme.orderUi.colors.secondary.dark};
	background-color: ${({ theme }) => theme.orderUi.colors.secondary.light};
	border-radius: 4px;
	margin-inline: 1px;
	cursor: pointer;
	line-height: 16px;

	${({ selected }) =>
		selected &&
		css`
			border: 2px solid ${({ theme }) => theme.orderUi.colors.primary.medium};
		`}

	${({ variant }) =>
		variant === RewardsButtonVariants.Secondary &&
		css`
			cursor: default !important;
			width: 230px;
			min-width: min-content;
			grid-template-columns: 1fr 0fr;
		`}

	${({ disabledCard }) =>
		disabledCard &&
		css`
			cursor: default !important;
			border: unset;
			background-color: ${({ theme }) => theme.orderUi.colors.neutral.medium};
		`}
`;

export const TextItems = styled.div<{ variant: RewardsButtonVariants }>`
	display: flex;
	flex-direction: column;
	gap: 4px;
	justify-content: flex-start;
	width: 100%;
	overflow: hidden;
`;

export const OffRewards = styled.div<{ variant: RewardsButtonVariants }>`
	display: flex;
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	${({ variant }) =>
		variant === RewardsButtonVariants.Secondary &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
			margin: auto;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		`}
`;

export const ValidAt = styled.div`
	display: flex;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Expires = styled.div`
	display: flex;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Value = styled.div<{ disabledCard: boolean }>`
	display: flex;
	justify-content: end;
	align-items: center;
	${({ theme }) => theme.orderUi.typography.headline.h5};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	${({ disabledCard }) =>
		disabledCard &&
		css`
			color: ${({ theme }) => theme.orderUi.colors.tertiary.medium};
		`}
`;

export const RedeemButton = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;
