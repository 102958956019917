import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import * as Styled from './CvcRevalidate.styled';
import { CvvOptions, CvvElement, TokenResult } from '@olo/pay';
import { Config } from '../../../Config';
import { useTheme } from 'styled-components';

export interface CvcRevalidateHandle {
	createCvvToken: () => Promise<TokenResult | undefined>;
	validateCardFields: () => boolean;
	focusOnCvc: () => void; 
}

const paymentEnvironment = Config.Env.IsPrd ? 'production' : 'development';

export const CvcRevalidate = forwardRef<CvcRevalidateHandle>(function CvcRevalidate(_, ref) {
	const [cvvElementState] = useState<CvvElement>(() => new CvvElement(paymentEnvironment));
	const [cardRevalidateCvcError, setRevalidateCardCvcError] = useState('');
	const [isInputEmpty, setIsInputEmpty] = useState(true);
	const { orderUi } = useTheme();

	useEffect(() => {
		const createCvvElements = async () => {
			const options: CvvOptions = {
				elementsOptions: {
					fonts: [
						{
							src: orderUi.fonts.src,
							family: orderUi.fonts.family,
						},
					],
				},
				cvvElementOptions: {
					placeholder: 'Enter your card CVC',
					style: {
						base: {
							fontSize: '16px',
							fontFamily: orderUi.fonts.family,
							'::placeholder': {
								color: orderUi.colors.tertiary.medium,
							},
						},
						invalid: {
							color: '#2C2C2B',
						},
					},
				},
			};

			await cvvElementState.create(options);

			cvvElementState?.element?.on('change', (e) => {
				setRevalidateCardCvcError('');
				setIsInputEmpty(e.empty);
				if (e.error) {
					setRevalidateCardCvcError(e.error.message);
				}
			});
		};
		createCvvElements();
	}, [cvvElementState]);

	useImperativeHandle(ref, () => ({
		async createCvvToken() {
			const response = await cvvElementState.createCvvToken();
			return response;
		},
		validateCardFields() {
			if (isInputEmpty) {
				setRevalidateCardCvcError(`Please enter your card's security code.`);
			}
			return !cardRevalidateCvcError && !isInputEmpty;
		},
		focusOnCvc() {
			cvvElementState.element?.focus();
		  }
	}));

	return (
		<Styled.Field error={cardRevalidateCvcError}>
			<Styled.Top>
				<Styled.Label>CVC</Styled.Label>
				<Styled.InputContainer data-testid='cvc-input-container'>
					<Styled.Input data-testid='cvc-input' data-olo-pay-cvv />
				</Styled.InputContainer>
			</Styled.Top>

			{cardRevalidateCvcError && (
				<Styled.Bottom>
					<Styled.Error>{cardRevalidateCvcError}</Styled.Error>
				</Styled.Bottom>
			)}
		</Styled.Field>
	);
});
