import React, { FC, useState, useEffect, useRef } from 'react';
import { HandoffHours } from '../HandoffHours';
import * as Styled from './RestaurantCard.styled';
import { Icon } from '@maverick/ui';
import { IconClock } from '@maverick/icons/dist/IconClock';
import { IconSleepMarker } from '@maverick/icons/dist/IconSleepMarker';
import { RestaurantInfo } from '../RestaurantInfo';
import { CallToAction } from '../CallToAction';
import { CalendarTypes, Restaurant, SearchedAddresses } from '@maverick/entity';
import { format } from 'date-fns';
import { handleKeyDown } from '../../../utils/GeneralUtils';
import { useTheme } from 'styled-components';
import { GaEvent, checkAllHandoffMethodsAvailability } from '@maverick/utils';

export interface RestaurantCardProps {
	restaurant: Restaurant;
	onClick: () => void;
	selected: boolean;
	expandItems: string;
	listIndex?: number;
	searchedAddresses: SearchedAddresses | null;
}

export const RestaurantCard: FC<RestaurantCardProps> = ({
	restaurant,
	onClick,
	selected,
	expandItems,
	listIndex = 0,
	searchedAddresses,
}) => {
	const { orderUi } = useTheme();
	const [expanded, setExpanded] = useState<boolean>(false);
	const supportsAdvanceOrders = restaurant.supportedtimemodes.includes('advance');
	const isRestaurantAvailable = checkAllHandoffMethodsAvailability(restaurant);

	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (selected && containerRef.current) {
			containerRef.current.scrollIntoView?.({ block: 'center' });
		}
	}, [selected]);

	const handleHours = (start: Date, end: Date) => {
		const startFormat = start.getMinutes() === 0 ? 'hhaaa' : 'hh:mmaaa';
		const endFormat = end.getMinutes() === 0 ? 'hhaaa' : 'hh:mmaaa';
		const startTime = format(start, startFormat);
		const endTime = format(end, endFormat);
		return `${startTime} - ${endTime}`;
	};

	const retrieveRestaurantHours = () => {
		const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		const businessCalendar = restaurant.calendar.find((calendar) => calendar.type === CalendarTypes.Business);
		const restaurantHours = weekDays.map((day) => {
			let weekDay = businessCalendar?.ranges.find((range) => range.weekday === day);
			let hours = weekDay ? handleHours(weekDay.start, weekDay.end) : 'Closed';

			return (
				<Styled.RestaurantHours role='list-item' key={day}>
					<Styled.Weekday>{day}</Styled.Weekday>

					<Styled.BusinessHours hours={hours}>{hours}</Styled.BusinessHours>
				</Styled.RestaurantHours>
			);
		});

		return restaurantHours;
	};

	const handleDirections = () => {
		const currentLocation = searchedAddresses?.userGeoLocation;
		GaEvent.GetDirectionsClick('restaurant_card');
		if (currentLocation) {
			const url = `https://www.google.com/maps/dir/?api=1&origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${restaurant.latitude},${restaurant.longitude}`;
			return window.open(url, '_blank');
		} else {
			const url = `https://www.google.com/maps/dir/?api=1&origin=&destination=${restaurant.latitude},${restaurant.longitude}`;
			return window.open(url, '_blank');
		}
	};

	const IconPin = () => {
		if (!isRestaurantAvailable) return IconSleepMarker;
		else if (selected) return orderUi.icons.iconLocation;
		else return orderUi.icons.iconLocationOutline;
	};

	return (
		<Styled.Container
			ref={containerRef}
			role='option'
			aria-selected={selected}
			tabIndex={0}
			onKeyDown={(ev) => handleKeyDown(ev, onClick)}
			onClick={onClick}
			selected={selected}
			data-testid={`restaurant-card-${listIndex}`}
		>
			<Styled.LocationContainer>
				<Styled.Location>
					<Styled.IconContainer isAvailable={isRestaurantAvailable || selected}>
						<Icon icon={IconPin()} customSize />
					</Styled.IconContainer>
				</Styled.Location>

				<RestaurantInfo restaurant={restaurant} listIndex={listIndex} />
			</Styled.LocationContainer>

			<Styled.HoursContainer>{<HandoffHours restaurant={restaurant} />}</Styled.HoursContainer>

			{!supportsAdvanceOrders && (
				<Styled.AlertContainer>
					<strong>This restaurant does not take advance orders</strong>
				</Styled.AlertContainer>
			)}

			{!expanded && (
				<Styled.MoreInfo>
					<CallToAction
						id={`more-info-cta-${listIndex}`}
						expanded={expanded}
						label={expandItems}
						onClick={() => setExpanded(true)}
					/>
				</Styled.MoreInfo>
			)}

			<Styled.Directions>
				<CallToAction
					id={`directions-cta-${listIndex}`}
					expanded={false}
					label='Directions'
					onClick={handleDirections}
				/>
			</Styled.Directions>

			{expanded && (
				<Styled.ExpandedItems aria-hidden={!expanded} tabIndex={0} data-testid='expanded-items'>
					<Styled.Line />

					<Styled.SectionTitle>
						<Styled.IconTitle>
							<Icon icon={IconClock} customSize />
						</Styled.IconTitle>

						<Styled.Title>Restaurant hours</Styled.Title>
					</Styled.SectionTitle>

					<Styled.SectionContent role='list'>{retrieveRestaurantHours()}</Styled.SectionContent>

					<Styled.Line />

					<Styled.SectionTitle>
						<Styled.IconTitle>
							<Icon icon={orderUi.icons.iconCall} customSize />
						</Styled.IconTitle>

						<Styled.Title>Contact us</Styled.Title>
					</Styled.SectionTitle>

					<Styled.SectionContent>
						<Styled.ContactUs>{restaurant.telephone}</Styled.ContactUs>
					</Styled.SectionContent>

					<Styled.LessInfo>
						<CallToAction
							id='less-info-cta'
							expanded={expanded}
							label='Less info'
							onClick={() => setExpanded(false)}
						/>
					</Styled.LessInfo>
				</Styled.ExpandedItems>
			)}
		</Styled.Container>
	);
};
