import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../utils/GeneralUtils';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`;

export const Title = styled.span`
	${({ theme }) => theme.orderUi.typography.title.subtitle};

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.buttonLarge};
	}
`;

export const Distance = styled.span`
	white-space: nowrap;
	${({ theme }) => theme.orderUi.typography.caption.caption};

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body};
	}
`;

export const Adress = styled.span`
	margin: 4px 0 0;
	${({ theme }) => theme.orderUi.typography.caption.caption};

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body};
	}
`;

export const DotContainer = styled.span`
	font-size: 20px;
	line-height: 0;
`;
