import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../utils/GeneralUtils';

export const Container = styled.div``;

export const Title = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h3};
	margin-bottom: 4px;
`;

export const Subtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodyLarge};
`;

export const ContainerResponsive = styled.div`
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		display: flex;
		gap: 8px;
		justify-content: center;
	}
`;

export const TitleResponsive = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h3};
	margin-bottom: 4px;
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		justify-content: center;
		margin-bottom: 0;
	}
`;

export const SubtitleResponsive = styled.div`
	${({ theme }) => theme.orderUi.typography.body.body};
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h3};
	}
`;
