import { Product } from '@maverick/entity';
import { FC } from 'react';
import * as Styled from './ProductCard.styled';
import { ProductImage } from '@maverick/ui';
import { handleKeyDown } from '../../../utils/GeneralUtils';
import { useTheme } from 'styled-components';
import { Tag } from '../Tag';
import { handleTagExhibition } from '../../../utils/MenuUtils';
import { useParams, Link } from 'react-router-dom';

export interface ProductCardProps {
	product: Product;
	onClick?: () => void;
	imageBasePath: string;
}
export const ProductCard: FC<ProductCardProps> = ({ product, onClick = () => {}, imageBasePath }) => {
	const { orderUi } = useTheme();
	const image = product.images?.find((i) => i.groupname === 'mobile-webapp-menu')?.filename;
	const imagePath = image ? `${imageBasePath}${image}` : `${orderUi.images.productPlaceholder}`;
	const formattedProductName = product.name.replace(/[^a-zA-Z0-9]/g, '');
	const displayTag = handleTagExhibition(product);

	const { restaurantSlug } = useParams();

	return (
		<Styled.Container
			id={product.id.toString()}
			onClick={onClick}
			onKeyDown={(ev) => handleKeyDown(ev, onClick)}
			data-testid={`${product.id}-product-card`}
			tabIndex={0}
		>
			{!!displayTag.type && displayTag.active ? <Tag variant='product' type={displayTag.type} /> : null}
			<Styled.ProductContainer>
				<Styled.ProductImageContainer id='product-image-container'>
					<span aria-hidden='true'>
						<ProductImage src={imagePath} alt={product.name} borderRadius={2} />
					</span>
					<Styled.ProductName data-testid='product-name'>
						<Link to={`/menu/${restaurantSlug}/products/${product.id}`} title={`Go to ${product.name}`}>
							{product.name}
						</Link>
					</Styled.ProductName>
				</Styled.ProductImageContainer>
				{!!product.cost && product.cost > 0 && (
					<Styled.ProductValueContainer>
						<Styled.ValueTitle>Starting at</Styled.ValueTitle>
						<Styled.ProductValue
							id={`${formattedProductName}-value`}
							data-testid={`${formattedProductName}-cost`}
						>
							${product.cost.toFixed(2)}
						</Styled.ProductValue>
					</Styled.ProductValueContainer>
				)}
			</Styled.ProductContainer>
		</Styled.Container>
	);
};
