import { BasketProduct, Menu, OptionGroup, Product, Option } from '@maverick/entity';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface MenuStore {
	menu: Menu | null;
	selectedProduct: Product | null;
	optionGroups: Array<OptionGroup>;
	rawOptionGroups: Array<OptionGroup>;
	selectedOptions: Array<number>;
	editedProduct: BasketProduct | null;
	redirectRouteMenu: string | null;
	selectOption?: number | null;
	addCartOrigin: string;
}
	
const initialState: MenuStore = {
	menu: null,
	selectedProduct: null,
	optionGroups: [],
	rawOptionGroups: [],
	selectedOptions: [],
	editedProduct: null,
	redirectRouteMenu: null,
	addCartOrigin: '',
};

interface OptionContext {
	optionGroup: OptionGroup;
	option: Option;
}

const deepClone = <T>(obj: T): T => {
	return structuredClone(obj) as T;
};

const normalizeOptionGroups = (original: Array<OptionGroup>, selectedOptions: Array<number>): Array<OptionGroup> => {
	const originalClone = deepClone(original);
	const normalized = new Array<OptionGroup>();

	const normalizeMasterOptionGroup = (optionGroup: OptionGroup, isRoot: boolean = false) => {
		const optionGroupClone = deepClone(optionGroup);

		optionGroup.options.forEach((o) => (o.modifiers = null));

		if (isRoot) normalized.push(optionGroup);

		for (const option of optionGroupClone.options) {
			if (selectedOptions.includes(option.id)) {
				const optionGroupAlreadyAdded = normalized.find((og) => og.id === optionGroup.id);
				if (!optionGroupAlreadyAdded) normalized.push(optionGroup);

				option.modifiers?.forEach((modifier) => {
					if (!OptionGroup.IsMasterOptionGroup(optionGroup)) {
						normalized.push(modifier);
					} else {
						normalizeMasterOptionGroup(modifier);
					}
				});
			}
		}
	};

	for (const optionGroup of originalClone) {
		if (!OptionGroup.IsMasterOptionGroup(optionGroup)) {
			normalized.push(optionGroup);
			continue;
		}

		normalizeMasterOptionGroup(optionGroup, true);
	}

	return normalized;
};

export const normalizeAllOptions = (original: Array<OptionGroup>): Array<OptionGroup> => {
	const originalClone = deepClone(original);
	const normalized = new Array<OptionGroup>();

	const normalizeMasterOptionGroup = (optionGroup: OptionGroup, isRoot: boolean = false) => {
		const optionGroupClone = deepClone(optionGroup);

		optionGroup.options.forEach((o) => (o.modifiers = null));

		if (isRoot) {
			normalized.push(optionGroup);
		}

		for (const option of optionGroupClone.options) {
			const optionGroupAlreadyAdded = normalized.find((og) => og.id === optionGroup.id);
			if (!optionGroupAlreadyAdded) {
				normalized.push(optionGroup);
			}

			option.modifiers?.forEach((m) => {
				if (!OptionGroup.IsMasterOptionGroup(optionGroup))
					normalized.push(m);
				else
					normalizeMasterOptionGroup(m);
			});
		}
	};

	for (const optionGroup of originalClone) {
		if (!OptionGroup.IsMasterOptionGroup(optionGroup)) {
			normalized.push(optionGroup);
			continue;
		}

		normalizeMasterOptionGroup(optionGroup, true);
	}
	return normalized;
};

export const getOptionContext = (optionGroups: Array<OptionGroup>, id: number): OptionContext | null => {
	for (const og of optionGroups) {
		for (const o of og.options) {
			if (o.id === id) {
				return {
					optionGroup: og,
					option: o,
				};
			}

			if (o.modifiers?.length) {
				const context = getOptionContext(o.modifiers, id);
				if (context) return context;
			}
		}
	}

	return null;
};

export const getSubOptions = (option: Option): Array<number> => {
	const subOptions = new Array<number>();

	const run = (option: Option) => {
		if (!option.modifiers?.length) return;
		for (const m of option.modifiers) {
			for (const o of m.options) {
				subOptions.push(o.id);
				getSubOptions(o);
			}
		}
	};
	run(option);

	return subOptions;
};

export const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setSelectedProduct: (state, { payload }: PayloadAction<MenuStore['selectedProduct']>) => {
			state.selectedProduct = payload;
		},
		setRestaurantMenu: (state, { payload }: PayloadAction<MenuStore['menu']>) => {
			state.menu = payload;
		},
		setOptionGroups: (state, { payload }: PayloadAction<MenuStore['optionGroups']>) => {
			const normalizedOptionGroups = normalizeOptionGroups(payload, state.selectedOptions);
			state.optionGroups = normalizedOptionGroups;
			state.rawOptionGroups = payload;
		},
		selectOption: (state, { payload }: PayloadAction<MenuStore['selectOption']>) => {
			const id = payload;
			if (!id) {
				state.selectedOptions = initialState.selectedOptions;
				state.optionGroups = initialState.optionGroups;
				return;
			}

			let _selectedOptions = [...current(state.selectedOptions)];

			const context = getOptionContext(current(state.rawOptionGroups), id);
			if (!context) {
				return;
			}

			const isMultiSelect = OptionGroup.IsMultiSelect(context.optionGroup);
			const items = context.optionGroup.options.map((o) => o.id);

			const isSelected = _selectedOptions.includes(id);
			if (isSelected) {
				if (isMultiSelect) {
					_selectedOptions = _selectedOptions.filter((i) => i !== id);

					if (context.option.modifiers?.length) {
						const subOptions = getSubOptions(context.option);
						_selectedOptions = _selectedOptions.filter((i) => !subOptions.includes(i));
					}
				} else if (!context.optionGroup.mandatory) {
					context.optionGroup.options.forEach((o) => {
						const subOptions = getSubOptions(o);
						_selectedOptions = _selectedOptions.filter((o) => !subOptions.includes(o));
					});
					_selectedOptions = _selectedOptions.filter((i) => !items.includes(i));
				}
			} else {
				if (!isMultiSelect) {
					context.optionGroup.options.forEach((o) => {
						const subOptions = getSubOptions(o);
						_selectedOptions = _selectedOptions.filter((o) => !subOptions.includes(o));
					});
					_selectedOptions = _selectedOptions.filter((i) => !items.includes(i));
				}

				_selectedOptions.push(id);
			}

			const optionGroups = normalizeOptionGroups(current(state.rawOptionGroups), _selectedOptions);

			state.selectedOptions = _selectedOptions;
			state.optionGroups = optionGroups;
		},
		resetCustomize: (state) => {
			state.selectedProduct = initialState.selectedProduct;
			state.selectedOptions = initialState.selectedOptions;
			state.editedProduct = initialState.editedProduct;
			state.optionGroups = initialState.optionGroups;
		},
		setEditedOptions: (state, { payload }: PayloadAction<MenuStore['selectedOptions']>) => {
			state.selectedOptions = payload;
		},
		setEditedProduct: (state, { payload }: PayloadAction<MenuStore['editedProduct']>) => {
			state.editedProduct = payload;
		},
		setRedirectRouteMenu: (state, { payload }: PayloadAction<MenuStore['redirectRouteMenu']>) => {
			state.redirectRouteMenu = payload;
		},

		setAddCartOrigin: (state, { payload }: PayloadAction<MenuStore['addCartOrigin']>) => {
			state.addCartOrigin = payload;
		},

		resetMenu: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => ({
			...state,
			...action,
		}));
	},
});
