import {
	Basket,
	FeatureFlagHighlight,
	GiftCardItem,
	HandoffMethods,
	ItemAutocomplete,
	Menu,
	Restaurant,
} from '@maverick/entity';
import { UserInfo } from '../shared/enums';
import { DateWithoutUTCFormat, GaEvent, UtilsGetDateDiffInDays, checkRestaurantAvailability } from '@maverick/utils';
import { isAfter, isBefore } from 'date-fns';
import { HandoffManager } from '../features/HandoffMethod/Handoff.manager';
import { BillingMethods } from '../shared/enums/BillingMethod';
import { DigitalWalletsSubmitBody } from '../shared/components/DigitalWalletsPayment';

export const DESKTOP_BREAKPOINT = 1280;
export const ULTRAWIDE_BREAKPOINT = 1920;

export const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

export const isDesktop = () => {
	const { width } = getWindowDimensions();
	return width >= DESKTOP_BREAKPOINT;
};

const findAvailableRestaurant = (restaurantList: Restaurant[], handoffMethod: HandoffMethods) => {
	let availableRestaurant = null;
	restaurantList.forEach((restaurant) => {
		const isRestaurantAvailableToOrder = checkRestaurantAvailability(restaurant, handoffMethod);
		if (isRestaurantAvailableToOrder) {
			availableRestaurant = restaurant;
			return;
		}
	});
	return availableRestaurant;
};

export const getOpenedRestaurant = (restaurantList: Restaurant[], handoffMethod: HandoffMethods) => {
	let openedRestaurant = null;

	if (handoffMethod === HandoffMethods.Curbside || handoffMethod === HandoffMethods.Pickup) {
		openedRestaurant = findAvailableRestaurant(restaurantList, handoffMethod);
		if (!openedRestaurant) {
			const secondHandoffOption =
				handoffMethod === HandoffMethods.Curbside ? HandoffMethods.Pickup : HandoffMethods.Curbside;
			openedRestaurant = findAvailableRestaurant(restaurantList, secondHandoffOption);
		}
	}

	if (handoffMethod === HandoffMethods.Delivery) {
		openedRestaurant = findAvailableRestaurant(restaurantList, handoffMethod);
	}

	return openedRestaurant;
};

export const handleKeyDown = (ev: React.KeyboardEvent, action: () => void) => {
	if (ev.key === 'Enter' || ev.key === ' ') {
		action();
		ev.stopPropagation();
		ev.preventDefault();
	}
};

export const calculateBasketValue = (basket: Basket | null, giftCardState: Array<GiftCardItem>) => {
	if (!basket) return 0;

	const deducedBasketValue =
		basket.total - giftCardState.reduce((previous, current) => current.balance + previous, 0);
	return deducedBasketValue < 0 ? 0 : deducedBasketValue;
};

export const fetchUserInfoFromToken = (decodedToken: any) => {
	const userInfoPrefix = 'https://dine-Rewards/';

	const userInfo = {
		givenName: decodedToken[userInfoPrefix + UserInfo.GivenName],
		familyName: decodedToken[userInfoPrefix + UserInfo.FamilyName],
		email: decodedToken[userInfoPrefix + UserInfo.Email],
		mobileNumber: decodedToken[userInfoPrefix + UserInfo.MobileNumber],
	};

	return userInfo;
};

export const orderAppOnlyProduct = (deepLink: string, appStoreAndroid: string, appStoreIos: string) => {
	window.location.href = deepLink;

	setTimeout(() => {
		window.location.href = selectStoreUrl(appStoreAndroid, appStoreIos);
	}, 4000);
};

export const redeemInPersonClick = (
	page: string,
	deepLink: string,
	appStoreAndroid: string,
	appStoreIos: string,
	featureHighlight?: FeatureFlagHighlight | null
) => {
	redirectToApp(page, deepLink, featureHighlight);

	setTimeout(() => {
		redirectToAppStore(page, appStoreAndroid, appStoreIos);
	}, 4000);
};

export const redirectToApp = (page: string, deepLink: string, featureHighlight?: FeatureFlagHighlight | null) => {
	GaEvent.RedeemRewardAppClick(page, deepLink);
	if (featureHighlight) {
		const newFeature = handleTagNew(featureHighlight);
		if (newFeature) {
			GaEvent.RedeemRewardHighlightButtonClick(page, deepLink);
		}
	}
	window.location.href = deepLink;
};

const selectStoreUrl = (appStoreAndroid: string, appStoreIos: string) => {
	const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
	const storeUrl = isIOS ? appStoreIos : appStoreAndroid;
	return storeUrl;
};

export const redirectToAppStore = (page: string, appStoreAndroid: string, appStoreIos: string) => {
	const storeUrl = selectStoreUrl(appStoreAndroid, appStoreIos);
	GaEvent.RedeemRedirectAppStore(page, storeUrl);

	window.location.href = storeUrl;
};

export const isBasketExpired = (basketCreationDate: Date | null) => {
	const staleBasket =
		!basketCreationDate || UtilsGetDateDiffInDays(basketCreationDate, DateWithoutUTCFormat(new Date())) >= 7;
	return staleBasket;
};

export const handleTagNew = (featureHighlight?: FeatureFlagHighlight): boolean => {
	const startDate = featureHighlight?.displayStartDate;
	const endDate = featureHighlight?.displayEndDate;

	if (!startDate || !featureHighlight.active) return false;

	const defaultDisplayDuration = 60 * 24 * 60 * 60 * 1000;
	const currentDate = new Date();
	const displayStartDate = new Date(startDate);
	const displayEndDate = endDate ? new Date(endDate) : new Date(displayStartDate.getTime() + defaultDisplayDuration);

	const isAfterStartDate = isAfter(new Date(currentDate), displayStartDate);
	const isBeforeEndDate = isBefore(new Date(currentDate), displayEndDate);

	return isAfterStartDate && isBeforeEndDate;
};

export const fetchAddressSuggestions = async (searchedValue: string) => {
	if (!searchedValue) {
		return [];
	}

	const addresses = await HandoffManager.GetDeliveryAddressAutocomplete(searchedValue);

	if (!addresses || typeof addresses === 'string') {
		return [];
	}
	GaEvent.AutoCompleteViewed();
	return addresses;
};

export const validateDeliverySearch = async (searchedAddress: ItemAutocomplete) => {
	let error = null;

	if (!searchedAddress.value.city) error = 'Please enter your city';
	if (!searchedAddress.value.number) error = 'Please enter your street number';
	if (!searchedAddress.value.street) error = 'Please enter your street address';
	if (!searchedAddress.value.postalCode) error = 'Please enter your postal code';

	return error;
};

export const getUserCoordinates = async (options: { pageLoad?: boolean } = {}) => {
	const getCurrentPosition = async () => {
		return new Promise<GeolocationCoordinates | null>((resolve, _) => {
			navigator.geolocation.getCurrentPosition(
				(success) => {
					GaEvent.GeoLocationSuccess();
					return resolve(success.coords);
				},
				(error) => {
					GaEvent.ErrorMessage(error.message);
					return resolve(null);
				}
			);
		});
	};

	if (!options.pageLoad) {
		return getCurrentPosition();
	}

	const { state } = await navigator.permissions.query({
		name: 'geolocation',
	});

	switch (state) {
		case 'granted':
			return getCurrentPosition();
		case 'prompt':
			GaEvent.GeoLocationDisplay();
		case 'denied':
			GaEvent.GeoLocationFailure();
		default:
			return null;
	}
};

const alcoholicDrinksIds = (menu: Menu) => {
	const ids: number[] = [];
	for (const category of menu?.categories ?? []) {
		if (category.containsalcohol) {
			for (const product of category.products ?? []) {
				ids.push(product.id);
			}
		}
	}
	return ids;
};

export const validBasketDollarsPoints = (basket: Basket, menu: Menu): number => {
	let validDollars: number = basket?.subtotal ?? 0;
	const products = basket?.products ?? [];
	const drinkIds = alcoholicDrinksIds(menu);

	for (const product of products) {
		if (drinkIds.includes(product.productId)) {
			validDollars -= product.basecost;
		}
	}
	return validDollars;
};

export const calculatePoints = (basket: Basket, menu: Menu) => {
	const validDollars = validBasketDollarsPoints(basket, menu);
	let calculatedPoints: number = 0;

	calculatedPoints = Math.floor(validDollars) * 5;
	calculatedPoints = calculatedPoints <= 0 ? 0 : calculatedPoints;

	return calculatedPoints;
};

export const getPaymentType = (billingMethod: BillingMethods, giftCardState: Array<GiftCardItem>, basket: Basket) => {
	const giftCardPay = giftCardState.reduce((sum, card) => sum + card.balance, 0);
	if (giftCardPay >= basket.total) 
		return 'gift card';
	if (!giftCardState.length) {
		switch (billingMethod) {
			case BillingMethods.CreditCard:
			case BillingMethods.CreditCardToken:
			case BillingMethods.BillingAccount:
				return 'credit card';
			case BillingMethods.Cash:
			case BillingMethods.PayInStore:
				return 'payment in store';
			case BillingMethods.DigitalWallet:
				const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
				if (isIOS) return 'apple pay';
				return 'google pay';
			default:
				return billingMethod;
		}
	}
	switch (billingMethod) {
		case BillingMethods.CreditCard:
		case BillingMethods.CreditCardToken:
		case BillingMethods.BillingAccount:
			return 'credit card and gift card';
		case BillingMethods.DigitalWallet:
			const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
			if (isIOS) return 'apple pay and gift card';
			return 'google pay and gift card';
		default:
			return billingMethod;
	}
};

export const getCardType = (
	billingMethod: BillingMethods,
	creditCardType?: string,
	digitalWalletsInfo?: DigitalWalletsSubmitBody
) => {
	let cardPayment = [BillingMethods.CreditCard, BillingMethods.CreditCardToken, BillingMethods.BillingAccount];
	if (cardPayment.includes(billingMethod)) {
		if (creditCardType === `amex`) return 'americanexpress';
		else return creditCardType;
	} else if (billingMethod === BillingMethods.DigitalWallet) {
		if (digitalWalletsInfo?.cardtype === `amex`) return 'americanexpress';
		else return digitalWalletsInfo?.cardtype ?? null;
	}
	return null;
};