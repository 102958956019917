import { TABLET_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const Container = styled.div`
	height: 100%;
`;

export const Subtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	margin: 8px 0 0;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.bodySmall};
	}
`;

export const SliderContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 16px;
	position: relative;
`;

export const Slider = styled.div`
	& > .slider {
		margin: 8px 0 0;
		display: flex;
		align-items: center;
		width: 100%;
		height: 30px;
		cursor: pointer;
		z-index: 1;
	}
`;

export const CustomTrack = styled.div`
	background: linear-gradient(90deg, #f8394d 0%, #83121e 100%);
	height: 5px;
	border-radius: 64px;
	position: absolute;
	width: 100%;
	left: 0;
	top: 20px;
`;

export const Thumb = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	line-height: 25px;
	width: 30px;
	background: linear-gradient(90deg, #f8394d 0%, #83121e 100%);
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	border: 1.5px solid ${({ theme }) => theme.orderUi.colors.neutral.light};
	border-radius: 50%;
	background-color: linear-gradient(90deg, #f8394d 0%, #83121e 100%);
	cursor: pointer;
	:focus-visible {
		outline-offset: 5px;
	}

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		height: 40px;
		width: 40px;
	}
`;

export const TemperatureOptions = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2px;
`;

export const TemperatureOption = styled.div<{ selected: boolean }>`
	width: 28px;
	text-align: center;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.medium};
	${({ theme }) => theme.orderUi.typography.body.body};
	cursor: pointer;

	${({ selected }) =>
		selected &&
		css`
			${({ theme }) => theme.orderUi.typography.title.subtitle};
			color: #ac0822;
			cursor: default;

			${Circle} {
				background: linear-gradient(222.05deg, #83121e 5.53%, #cc0025 86.58%);
			}
		`}
`;

export const Circle = styled.div`
	margin: 12px auto 8px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: ${({ theme }) => theme.orderUi.colors.tertiary.medium};

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		width: 12px;
		height: 12px;
	}
`;

export const ThumbIcon = styled.div`
	width: 12px;
	height: 22px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		width: 16px;
		height: 30px;
	}
`;

export const Description = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	display: flex;
	align-items: center;
	margin-top: 16px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.bodySmall};
	}
`;
export const DescriptionIcon = styled.div`
	width: 10px;
	height: 10px;
	margin-right: 8px;
	color: ${({ theme }) => theme.orderUi.colors.complementary.error};

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		width: 16px;
		height: 16px;
	}
`;
