import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const InPersonColumn = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: center;
	width: 100%;
	display: flex;
	gap: 16px;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: max-content;
		align-items: flex-start;
		padding: 20px 24px;
		white-space: nowrap;
	}
`;

export const ButtonRewardsInPerson = styled.div`
	font-family: unset;
	${({ theme }) => theme.orderUi.typography.inputs.button}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.button}
	}

	& > span {
		color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	}
`;

export const Icon = styled.div`
	all: unset;
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	width: 30px;
	cursor: pointer;

	&:focus-visible {
		outline: solid 2px ${({ theme }) => theme.orderUi.colors.neutral.dark};
	}

	&:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.lightVariant};
	}
`;
