import styled from 'styled-components';

export const Container = styled.div<{}>`
	width: 100%;
	position: relative;
	margin: auto;
`;

export const Welcome = styled.div<{}>`
	margin-bottom: 24px;
`;

export const CheckRewards = styled.div<{}>``;

export const Title = styled.div`
	all: unset;
	${({ theme }) => theme.orderUi.typography.headline.h4}
	margin-right: 32px;
	white-space: nowrap;
`;
