import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '@maverick/utils';

export const Container = styled.div`
	position: relative;
	cursor: pointer;

	&:focus-visible {
		outline: -webkit-focus-ring-color auto 1px;
	}
`;

export const ProductContainer = styled.div`
	aspect-ratio: 1/1;
`;

export const ProductImageContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

export const ProductName = styled.h3`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium};
	padding: 8px;
	width: 100%;
	position: absolute;
	bottom: 0;
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	background: rgba(0, 0, 0, 0.75);
	border-radius: 0 0 2px 2px;

	@media screen and (min-width: 500px) {
		${({ theme }) => theme.typography.caption.captionLarge};
	}

	@media screen and (min-width: 768px) {
		${({ theme }) => theme.typography.caption.captionMedium};
	}

	@media screen and (min-width: 1024px) {
		${({ theme }) => theme.typography.caption.captionLarge};
	}

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.typography.caption.captionMedium};
	}

	@media screen and (min-width: 1440px) {
		${({ theme }) => theme.typography.caption.captionLarge};
	}
`;

export const ProductValueContainer = styled.span`
	margin-top: 8px;
	display: flex;
	justify-content: end;
	align-items: baseline;
`;

export const ValueTitle = styled.span`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const ProductValue = styled.span`
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin-left: 8px;
`;
