import styled from 'styled-components';
import { TABLET_BREAKPOINT } from '@maverick/utils';

export const Container = styled.div`
	width: 100%;
`;

export const Title = styled.h2`
	all: unset;
	${({ theme }) => theme.orderUi.typography.headline.h4}

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h3}
	}

	& a {
		&:hover {
			opacity: 0.9;
		}
		&:focus {
			outline: auto 2px ${({ theme }) => theme.orderUi.colors.neutral.dark};
		}
	}
`;
