import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../../utils/GeneralUtils';

export const Container = styled.div`
	position: relative;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	justify-content: space-around;
	display: flex;
	flex-direction: column;
	height: 100%;

	#total-value {
		${({ theme }) => theme.orderUi.typography.headline.h5};
	}

	#total {
		${({ theme }) => theme.orderUi.typography.headline.h5};
	}
`;

export const Body = styled.div`
	padding: 0 24px 0;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	padding-bottom: 16px;
	height: 100%;
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 0 32px 0;
		padding-bottom: 16px;
	}
`;

export const ComponentTitle = styled.div`
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	z-index: 1;
	padding-top: 24px;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding-top: 32px;
	}
`;

export const Close = styled.button`
	background: none;
	border: none;
	padding: 0;
	position: absolute;
	top: 29px;
	right: 0px;
	cursor: pointer;
	transition: all ease 0.1s;

	&:hover {
		opacity: 0.6;
	}

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		top: 37px;
	}
`;

export const Summary = styled.div``;

export const SummaryTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const SummaryProducts = styled.div`
	margin: 16px 0 28px 0;
	display: flex;
	gap: 24px;
	flex-direction: column;
`;

export const BasketOptions = styled.div`
	hr {
		background-color: ${({ theme }) => theme.orderUi.colors.neutral.medium};
		border: none;
		height: 1.5px;
		margin: 24px 0;
	}
`;

export const Options = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
`;

export const ClearBasketContainer = styled.div`
	margin-top: 24px;
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
`;

export const ClearBasketOptions = styled.div`
	margin-top: 8px;
	display: flex;
	flex-direction: row;
	gap: 24px;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Title = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h3};
`;

export const SubtitleSection = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h5};
`;

export const Optional = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.medium};
`;

export const Subtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium}
	margin-top: 16px;
`;

export const Section = styled.section``;

export const Email = styled.div`
	margin-top: 12px;
`;

export const Button = styled.div`
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;
	position: sticky;
	padding: 20px 24px;
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	bottom: 0;
	right: 0;
	width: 100%;
	box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 24px 32px;
	}
`;
export const ProductsCardContainer = styled.div`
	margin-top: 16px;
	display: flex;
	flex-wrap: wrap;
	gap: 16px 24px;
`;

export const OptionCard = styled.div`
	margin: 16px 0 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 24px;
`;

export const UtensilsInfo = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	display: flex;
	align-items: flex-start;
	margin: 16px 0px;
`;

export const EmptyUtensilsInfo = styled.div`
	margin: 16px 0px;
`;

export const Icon = styled.div`
	margin: 4px 8px 0 0;
	width: 16px;
`;

export const InputContainerDesktop = styled.div`
	margin-top: 12px;
	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		display: none;
	}
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		flex: 0.3;
	}
`;

export const InputContainerMobile = styled.div`
	margin-top: 12px;
	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		flex: 0.3;
	}
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		display: none;
	}
`;

export const NotLoggedUserContainer = styled.div`
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;
	position: sticky;
	padding: 24px 32px;
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	bottom: 0;
	right: 0;
	width: 100%;
	box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);

	display: flex;
	gap: 16px;
	justify-content: center;

	> div {
		flex: 1;
	}
`;