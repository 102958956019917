import { FC } from 'react';
import { OptionGroup } from '@maverick/entity';
import { OptionsCheckboxList } from '../OptionsCheckboxList';
import { OptionsCardList } from '../OptionsCardList';
import { TemperatureSlider } from '../TemperatureSlider';
import { useIsMobile } from '@maverick/hooks';
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT, ULTRAWIDE_BREAKPOINT } from '@maverick/utils';
import { useTheme } from 'styled-components';
import { useModal } from '@maverick/ui';

import * as Styled from './OptionGroupSection.styled';

export interface OptionGroupSectionProps {
	optionGroup: OptionGroup;
	isUpdate: boolean;
	isNested?: boolean;
	isAppOnly?: boolean;
	handleClick?: () => void;
}
export const OptionGroupSection: FC<OptionGroupSectionProps> = ({
	optionGroup,
	isUpdate,
	isNested = false,
	isAppOnly = false,
	handleClick,
}) => {
	const aditionalBreakpoint = useIsMobile(600);
	const tabletBreakpoint = useIsMobile(TABLET_BREAKPOINT);
	const desktopBreakpoint = useIsMobile(DESKTOP_BREAKPOINT);
	const ultrawideBreakpoint = useIsMobile(ULTRAWIDE_BREAKPOINT);
	const maxSelects = Number(optionGroup.maxselects);
	const minSelects = Number(optionGroup.minselects);
	const { setModal } = useModal();
	const { orderUi } = useTheme();

	const handleOptionsCardGrid = () => {
		if (aditionalBreakpoint) return 3;
		if (tabletBreakpoint) return 5;
		if (desktopBreakpoint) return 6;
		if (ultrawideBreakpoint) return 5;
		return 6;
	};

	const handleModifiersGrid = (optionsQty: number) => {
		if (optionsQty === 1) return 1;
		if (tabletBreakpoint) return 2;
		return 4;
	};

	const setTransferBasketModal = () => {
		setModal({
			text: `You will be directed to the ${orderUi.app.shortName} App, do you want to continue?`,
			primaryButton: { label: 'Continue', onClick: handleClick },
			secondaryButton: { label: 'Cancel' },
			id: 'warning-transfer-basket-modal',
		});
	};

	const mobileAppDisplay = optionGroup.metadata?.find((metadata) => metadata.key === 'mobile-app-display');

	const renderOptions = (optionGroup: OptionGroup) => {

		if (!mobileAppDisplay) {
			var subtitle = '';
			if (maxSelects && !minSelects) {
				subtitle = `Select up to ${maxSelects}:`;
			} else if (maxSelects && minSelects) {
				subtitle = `Select between ${minSelects} and ${maxSelects}:`;
			}

			return (
				<>
					{!!maxSelects && <Styled.SelectionSubtitle>{subtitle}</Styled.SelectionSubtitle>}
					<OptionsCheckboxList
						optionsGrid={handleModifiersGrid(optionGroup.options?.length || 0)}
						options={optionGroup.options}
						parentOption={optionGroup}
						isMultiSelect={OptionGroup.IsMultiSelect(optionGroup)}
						isUpdate={isUpdate}
						isAppOnly={isAppOnly}
						setTransferBasketModal={setTransferBasketModal}
					/>
				</>
			);
		}
		if (['size', 'card'].includes(mobileAppDisplay.value))
			return (
				<OptionsCardList
					cardsGrid={handleOptionsCardGrid()}
					options={optionGroup.options}
					isUpdate={isUpdate}
					optionGroup={optionGroup.description}
					isAppOnly={isAppOnly}
					setTransferBasketModal={setTransferBasketModal}
				/>
			);

		if (optionGroup.options?.length && mobileAppDisplay.value === 'temperature')
			return (
				<TemperatureSlider
					options={optionGroup.options}
					isUpdate={isUpdate}
				/>
			);
	};

	if (!optionGroup.options?.length && mobileAppDisplay?.value === 'temperature') {
		return <></>
	}

	return (
		<Styled.Container
			aria-labelledby={`${optionGroup.description} ${optionGroup.mandatory ? 'required' : 'optional'}`}
			key={optionGroup.id}
			isNested={isNested}
			className='option-group'
		>
			<Styled.Top>
				{isNested ? (
					<Styled.Subtitle>{optionGroup.description}</Styled.Subtitle>
				) : (
					<>
						<Styled.Title>{optionGroup.description}</Styled.Title>
						<Styled.TitleObs mandatory={optionGroup.mandatory}>
							{optionGroup.mandatory ? 'required*' : 'optional'}
						</Styled.TitleObs>
					</>
				)}
			</Styled.Top>

			<Styled.Content>{renderOptions(optionGroup)}</Styled.Content>
		</Styled.Container>
	);
};
