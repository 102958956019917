import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const MessageSection = styled.div<{ displayStyle: 'compact' | 'full' | 'app' | 'pointsReach' | 'pointsEarn' }>`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	border-radius: 4px;
	padding: 16px;
	background-color: ${({ theme }) => theme.orderUi.colors.tertiary.light};
	${({ displayStyle }) => displayStyle === 'app' && 'align-items: center'};
	${({ displayStyle }) => displayStyle === 'full' && 'margin-top: 24px;'};
	${({ displayStyle, theme }) =>
		(displayStyle === 'pointsReach' || displayStyle === 'pointsEarn') &&
		` align-items: center;
			justify-content: center;
			background: ${theme.orderUi.colors.secondary.light};
			margin-top: 8px;
	`};
	${({ displayStyle }) =>
		displayStyle === 'pointsReach' &&
		`margin-top: 24px;
	`};
`;

export const Message = styled.div<{ variant?: 'app' | 'points' }>`
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	${({ variant, theme }) => variant === 'app' && theme.orderUi.typography.inputs.buttonSmall};
	${({ variant }) => variant === 'points' && `text-align: center;`};
`;

export const InfoIcon = styled.div<{ variant: 'info' | 'app' | 'points' }>`
	width: 24px;
	height: 24px;
	margin-right: 8px;
	${({ variant }) => variant === 'info' && 'width: 12px;height: 12px; margin-top: 2px;'};
	${({ variant, theme }) =>
		variant === 'points' &&
		`color: ${theme.orderUi.colors.secondary.variant}; align-self: flex-start; margin-top: -2px`};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		margin-right: 16px;
		width: 24px;
		height: 24px;
		${({ variant }) => variant === 'info' && 'width: 14px;height: 14px; margin-top: 2px;'};
		${({ variant, theme }) => variant === 'points' && `color: ${theme.orderUi.colors.secondary.variant};`};
	}
`;
