import styled, { css } from 'styled-components';
import { DESKTOP_BREAKPOINT } from '@maverick/utils';

export const Section = styled.section``;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Title = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h4Variant};
`;

export const Subtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium}
	margin: 8px 0px;
`;

export const DineRewardsBanner = styled.div`
	display: none;
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		display: block;
	}
`;

export const Line = styled.hr`
	background-color: ${({ theme }) => theme.orderUi.colors.neutral.medium};
	border: none;
	height: 1.5px;
	margin: 24px 0;
`;

export const ListWrapper = styled.div`
	position: relative;
	display: flex;
	overflow-y: hidden;
`;

export const ScrollWrapper = styled.div<{ hasMultipleRewards: boolean }>`
	display: flex;
	align-items: center;
	width: 100%;
	overflow-x: auto;
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	overflow-y: hidden;
	scroll-behavior: smooth;
	gap: 16px;

	${({ hasMultipleRewards }) =>
		!hasMultipleRewards &&
		css`
			padding: 0px 60px 30px 60px;
		`}
`;

export const ListGradientNext = styled.div`
	position: absolute;
	background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0.5) 80.64%, rgba(255, 255, 255, 0) 100%);
	width: 60px;
	height: 60px;
	right: 0px;
`;

export const ListGradientPrevious = styled.div`
	position: absolute;
	background: linear-gradient(90deg, #ffffff 50%, rgba(255, 255, 255, 0.5) 80.64%, rgba(255, 255, 255, 0) 100%);
	width: 60px;
	height: 60px;
	left: 0px;
`;

export const NextRewardButton = styled.div`
	position: absolute;
	height: min-content;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 20px;
	height: 20px;
`;

export const PreviousRewardButton = styled.div`
	position: absolute;
	height: min-content;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 20px;
	height: 20px;
`;
