import { FC } from 'react';
import * as Styled from './RecentAndFaves.styled';
import { Button } from '@maverick/ui';
import { CategoryHistoryItem, CategoryHistoryOptions } from './RecentAndFaves';
import { useAuth0 } from '@auth0/auth0-react';
import { Config } from '../../../../Config';
import { ReduxProps } from '.';
import {
	descriptionFavoriteGuest,
	descriptionRecentGuest,
	titleFavoriteGuest,
	titleRecentGuest,
} from '../../../../shared/constants';
import { GaEvent } from '@maverick/utils';

export interface RecentAndFavesCardGuestProps extends ReduxProps {
	categorySelected: CategoryHistoryItem;
}

export const RecentAndFavesCardGuest: FC<RecentAndFavesCardGuestProps> = ({ categorySelected, setRedirectRoute }) => {
	const { loginWithRedirect } = useAuth0();

	const title = categorySelected.key === CategoryHistoryOptions.Favorites ? titleFavoriteGuest : titleRecentGuest;
	const description =
		categorySelected.key === CategoryHistoryOptions.Favorites ? descriptionFavoriteGuest : descriptionRecentGuest;

	const onClickSignIn = () => {
		GaEvent.UserHasLoggedIn()
		setRedirectRoute(location.pathname);
		loginWithRedirect({ source: Config.Source, prompt: 'login' });
	};

	const onClickJoinNow = () => {
		GaEvent.InternalLinkClick(location.pathname)
		setRedirectRoute(location.pathname);
		loginWithRedirect({ source: Config.Source });
	};

	return (
		<Styled.GuestCardContainer
			tabIndex={0}
			selected={false}
			id={`recent-and-faves-guest`}
			data-testid={`recent-and-faves-guest`}
		>
			<Styled.Section>
				<Styled.GuestCardTitle title={title} tabIndex={0} data-testid={'recent-and-faves-guest-title'}>
					{title}
				</Styled.GuestCardTitle>
			</Styled.Section>
			<Styled.GuestSecondSection>
				<Styled.GuestDescription tabIndex={0} data-testid={'recent-and-faves-guest-description'}>
					{description}
				</Styled.GuestDescription>
				<Styled.GuestImageContainer>
					<Styled.DineRewardsIcon />
				</Styled.GuestImageContainer>
			</Styled.GuestSecondSection>
			<Styled.ThirdSection>
				<Styled.ButtonContainer>
					<Button
						data-testid={`sign-in`}
						text='Sign in'
						variant='secondary'
						onClick={onClickSignIn}
						id={`sign-in`}
					/>
				</Styled.ButtonContainer>
				<Styled.ButtonContainer>
					<Button
						data-testid={`join-now`}
						text='Join now'
						variant='primary'
						onClick={onClickJoinNow}
						id={`join-now`}
					/>
				</Styled.ButtonContainer>
			</Styled.ThirdSection>
		</Styled.GuestCardContainer>
	);
};
