import { TABLET_BREAKPOINT, DESKTOP_BREAKPOINT, ULTRAWIDE_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div`
	display: inline-flex;
	width: 100%;
`;

export const LeftContainer = styled.div`
	width: 100%;
	margin-bottom: 86px;
	min-height: calc(100vh - 226px);

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		flex: 1;
	}
`;

export const RightContainer = styled.div`
	background-color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	display: none;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		display: block;
		width: 50%;
	}
	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: ${ULTRAWIDE_BREAKPOINT / 2}px;
	}
`;

export const ProductImageContainer = styled.div`
	width: 100%;
	aspect-ratio: 390 / 197;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		aspect-ratio: 768 / 276;
	}

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		display: none;
	}
`;

export const ImageDesktopContainer = styled.div`
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		height: 30%;
		width: fill-available;
		position: relative;
	}

	@media screen and (min-height: 560px) {
		height: 40%;
	}

	@media screen and (min-height: 680px) {
		height: 50%;
	}
`;

export const Align = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 50%;
`;

export const NutritionalInformationMobile = styled.div`
	background-color: ${({ theme }) => theme.orderUi.colors.secondary.light};
`;

export const NutritionalInformationDesktop = styled.div`
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		flex: 1;
		background-color: ${({ theme }) => theme.orderUi.colors.secondary.light};
	}
`;

export const ProductCustomizeContainer = styled.div`
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
`;

export const Content = styled.div`
	padding: 24px;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 32px;
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: 896px;
		padding: 32px 0;
		margin: 0 auto;
	}
`;

export const ButtonReturn = styled.div`
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	display: flex;
	justify-content: flex-start;
	margin-bottom: 24px;
`;

export const ProductInfoContainer = styled.section`
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-weight: unset;
	${({ theme }) => theme.orderUi.typography.headline.h4};
	margin-bottom: 8px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h3};
	}
`;

export const Description = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body};
	}
`;

export const Calories = styled.div`
	margin-top: 8px;
	display: flex;
	align-items: center;
`;

export const CaloriesText = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	color: ${({ theme }) => theme.orderUi.colors.primary.variant};

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.bodySmall};
	}
`;

export const OptionGroupContainer = styled.div``;
export const OptionGroupWrapper = styled.div``;

export const TextureDetail = styled.div`
	height: 20px;
	width: 100%;
	background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Order/outback-customize-it-detail.png');
	background-repeat: repeat;
	background-position: top;
	position: absolute;
	bottom: 0;
`;

export const TextureDetailMobile = styled.div`
	height: 20px;
	width: 100%;
	background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Order/outback-customize-it-detail.png');
	background-repeat: repeat;
	background-position: top;
`;

export const OrderInformation = styled.div`
	margin-top: 24px;
`;

export const NutritionalInformation = styled.div``;

export const Information = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
`;

export const InfoTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h4Variant}
`;

export const InfoSubtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.title.subtitle}
`;

export const InfoDescription = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const ButtonWrapper = styled.div`
	position: fixed;
	padding: 20px 24px;
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	gap: 60px;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		right: unset;
		width: 50%;
		padding: 20px 32px;
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: calc(100% - 960px);
		padding: 20px 32px;
	}
	@media screen and (max-width: ${TABLET_BREAKPOINT}px) {
		flex-direction: column;
		gap: 16px;
	}
`;

export const Button = styled.div`
	width: 30%;
	min-width: 163px;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: 40%;
		max-width: 448px;
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: 50%;
		max-width: 448px;
	}

	@media screen and (max-width: ${TABLET_BREAKPOINT}px) {
		width: 100%;
	}
`;

export const FavoriteButtonContainer = styled.button`
	display: flex;
	align-items: center;
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	gap: 8px;
	padding: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.light};
	}
`;

export const FavoriteButtonText = styled.div``;

export const FavoriteButtonIcon = styled.div`
	width: 16px;
	height: 16px;
	min-width: 16px;
`;

export const AnimatedIconContainer = styled.div`
	position: relative;
	top: -1.5px;
	left: -11.5px;
	margin: 0 -11px 0 -12px;
	height: 16px;
	max-width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all ease 0.1s;
	position: relative;
`;
