import { useAuth0 } from '@auth0/auth0-react';
import { Basket } from '@maverick/entity';
import jwtDecode from 'jwt-decode';
import { UserManager } from '../../features/User/User.manager';
import { fetchUserInfoFromToken } from '../../utils/GeneralUtils';
import { useTheme } from 'styled-components';
import { Log } from '../../infrastructure/logger/Logger';

interface RenewLoginOptions {
	basket: Basket | null;
	setAuth0Token: (auth0Token: string) => void;
	setUserToken: (userToken: string) => void;
}

export const useToken = () => {
	const { getAccessTokenSilently } = useAuth0();
	const { orderUi } = useTheme();

	const renewLogin = async (options: RenewLoginOptions) => {
		try {
			let token = await getAccessTokenSilently();
			let decodedToken = jwtDecode(token);

			if (!decodedToken || !options.basket?.id) return;

			const { givenName, familyName, email, mobileNumber } = fetchUserInfoFromToken(decodedToken);
			const resp = await UserManager.AuthorizeUser(
				orderUi.app.providerAuth0,
				token,
				'providertoken',
				mobileNumber,
				options.basket.id,
				givenName,
				familyName,
				email
			);

			options.setAuth0Token(token);
			options.setUserToken(resp.tokenProvider?.authtoken!);
		} catch (error) {
			Log.Error('RenewLogin-error', error ?? {});
			return;
		}
	};

	return renewLogin;
};
