import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Styled from './PaymentInfo.styled';
import { InputHandler, useInput } from '@maverick/hooks';
import { CvcValidator } from './validation/Cvc.validator';
import { DigitalWalletsPaymentOptions } from '../../enums';
import { BillingMethods } from '../../enums/BillingMethod';
import { FlagValidator } from './validation/Flag.validator';
import { ZipCodeValidator } from './validation/Zip.validator';
import { UserManager } from '../../../features/User/User.manager';
import { GaEvent, InputModes, PaymentType } from '@maverick/utils';
import { CardNumberValidator } from './validation/CreditNumber.validator';
import { CheckoutManager } from '../../../features/Checkout/Checkout.manager';
import { ExpirationDateValidator } from './validation/ExpirationDate.validator';
import { Checkbox, CustomDropdown, Input, PaymentTile, useToast } from '@maverick/ui';
import {
	ValidationCreditCard,
	getBillingSchemeError,
	messageDigitalWallet,
	messageTipAtRestaurant,
	unavailablePaymentMethods,
} from '../../constants';
import { Basket, BillingScheme, GiftCardItem, HandoffMethods } from '@maverick/entity';
import { DigitalWalletsPayment, DigitalWalletsPaymentHandle, supportsDigitalWallets } from '../DigitalWalletsPayment';
import { PaymentMethod, PaymentMethodResult } from '@olo/pay';
import { calculateBasketValue } from '../../../utils/GeneralUtils';
import { CreditCardPayment, CreditCardPaymentHandle } from '../CreditCardPayment';
import { WarningMessage } from '../WarningMessage';
import { CvcRevalidate, CvcRevalidateHandle } from '../CvcRevalidate';

export type PaymentInfo = {
	selectedBillingAccountId: string;
	billingMethod: BillingMethods;
	expirationDate: string;
	cardNumber: string;
	saveCard: boolean;
	zipCode: string;
	cvc: string;
	selectedPaymentOption: PaymentOption;
};

export interface PaymentInfoHandle {
	validatePaymentForm: () => boolean;
	retrievePaymentInfo: () => Promise<PaymentInfo>;
	resetErrors: () => void;
	resetForm: () => void;
	initializePaymentDigitalWallets: (paymentType: PaymentType) => void;
	createCreditCardPaymentMethod: () => Promise<PaymentMethodResult | undefined>;
	focusFirstErrorField: () => void;
}

type PaymentOption = {
	type: PaymentType;
	label: string;
	id?: string;
	removable?: boolean;
};

interface PaymentInfoProps {
	onHandleBasketSubmition: () => void;
	setLoading: (loading: boolean) => void;
	setBillingScheme: (billingScheme: BillingScheme[]) => void;
	onHandleDigitalWalletsPayment: (method: PaymentMethod) => void;
	onHandleDigitalWalletsPaymentError: () => void;
	onHandleChangePaymentType: (paymentType: PaymentType) => void;
	handleDeliveryInstructions: () => Promise<boolean>;
	handleBasketCustomFields: () => Promise<boolean>;
	validateBasket: () => Promise<boolean>;
	setFormStarted: (formStarted: boolean) => void;
	giftCardState: GiftCardItem[];
	userToken: string | null;
	basket: Basket | null;
	paymentValue?: number;
	lastOrderPayment?: PaymentType;
	formStarted: boolean;
}

export const PaymentInfo = forwardRef<PaymentInfoHandle, PaymentInfoProps>(function PaymentInfo(
	{
		onHandleBasketSubmition,
		onHandleDigitalWalletsPayment,
		onHandleDigitalWalletsPaymentError,
		onHandleChangePaymentType,
		setLoading,
		setBillingScheme,
		handleDeliveryInstructions,
		handleBasketCustomFields,
		validateBasket,
		setFormStarted,
		giftCardState,
		userToken,
		basket,
		paymentValue,
		lastOrderPayment,
		formStarted,
	},
	ref
) {
	const pageTitle = 'PaymentInfo';
	const { setToast } = useToast();
	const { isAuthenticated } = useAuth0();
	const [saveCard, setSaveCard] = useState<boolean>(true);
	const [dropdownError, setDropdownError] = useState<string>('');
	const [expandCardFields, setExpandCardFields] = useState<boolean>(false);
	const [shouldFocusOnPayment, setShouldFocusOnPayment] = useState<boolean>(true);
	const digitalWalletsRef = useRef<DigitalWalletsPaymentHandle>(null);
	const creditCardPaymentRef = useRef<CreditCardPaymentHandle>(null);
	const cvcRevalidateRef = useRef<CvcRevalidateHandle>(null);
	const basketValue = calculateBasketValue(basket, giftCardState);
	const basketEmptyValue = basketValue === 0;

	const defaultPaymentLabel = !basketEmptyValue ? 'Select a payment method' : 'Payment method not required';
	const paymentTypeDefault = [{ type: PaymentType.Default, label: defaultPaymentLabel }];
	const [selectPaymentOption, setSelectPaymentOption] = useState<PaymentOption>({
		type: PaymentType.Default,
		label: defaultPaymentLabel,
	});
	const [paymentOptions, setPaymentOptions] = useState<Array<PaymentOption>>(paymentTypeDefault);
	const [expandDigitalWallets, setExpandDigitalWallets] = useState<boolean>(false);
	const [billingMethod, setBillingMethod] = useState<BillingMethods>(BillingMethods.Cash);
	const [selectedBillingAccountId, setSelectedBillingAccountId] = useState<string>('');
	const [supportsOlopay, setsupportsOlopay] = useState<boolean>(false);
	const [showMsgDigitalWallets, SetShowMsgDigitalWallets] = useState<boolean>(false);
	const [unavailableBillingScheme, setUnavailableBillingScheme] = useState<boolean>(false);
	const [billingSchemeConst, setBillingSchemeConst] = useState<BillingScheme[] | null>(null);

	const cardNumber: InputHandler<string> = useInput('', {
		id: 'card-number',
		label: 'Card Number',
		placeholder: '1234 5678 9101 1121',
		mask: 'xxxx xxxx xxxx xxxx xxx',
		onChange: (value: any) => cardNumber.setIcon?.(FlagValidator(value)),
		onBlur: () => CardNumberValidator(cardNumber),
	});
	const cvc: InputHandler<string> = useInput('', {
		id: 'cvc',
		label: 'CVC',
		placeholder: '123',
		maxlength: 4,
		onBlur: () => CvcValidator(cvc),
	});
	const expirationDate: InputHandler<string> = useInput('', {
		id: 'expiration-date',
		label: 'Exp. Date',
		placeholder: 'MM / YY',
		mask: 'xx/xx',
		onBlur: () => ExpirationDateValidator(expirationDate),
	});
	const zipCode: InputHandler<string> = useInput('', {
		id: 'zip',
		label: 'ZIP',
		placeholder: '72105',
		maxlength: 5,
		onBlur: () => ZipCodeValidator(zipCode),
	});

	useImperativeHandle(ref, () => ({
		validatePaymentForm(): boolean {
			const isValid = validatePaymentMethod();
			return isValid;
		},
		resetErrors() {
			expirationDate.setError('');
			cardNumber.setError('');
			zipCode.setError('');
			cvc.setError('');
		},
		resetForm() {
			expirationDate.setValue('');
			cardNumber.setValue('');
			zipCode.setValue('');
			cvc.setValue('');
		},
		async retrievePaymentInfo() {
			const cvcToken = await cvcRevalidateRef.current?.createCvvToken();
			return {
				selectedBillingAccountId: selectedBillingAccountId,
				selectedPaymentOption: selectPaymentOption,
				expirationDate: expirationDate.value,
				billingMethod:
					billingMethod === BillingMethods.CreditCard && supportsOlopay
						? BillingMethods.CreditCardToken
						: billingMethod,
				cardNumber: cardNumber.value,
				zipCode:
					billingMethod === BillingMethods.CreditCard && supportsOlopay
						? creditCardPaymentRef.current?.getZipCode()!
						: zipCode.value,
				saveCard: saveCard,
				cvc: cvcToken?.token?.id ?? cvc.value,
			};
		},
		async initializePaymentDigitalWallets(paymentType: PaymentType) {
			await digitalWalletsRef.current?.initializePaymentDigitalWallets(paymentType);
		},
		async createCreditCardPaymentMethod() {
			return await creditCardPaymentRef.current?.createPaymentMethod();
		},
		focusFirstErrorField: () => {
			if (supportsOlopay) {
				if (creditCardPaymentRef.current) {
					creditCardPaymentRef.current.focusFirstErrorField();
				} else if (cvcRevalidateRef.current && doCVVRevalidation()) {
					const isValid = cvcRevalidateRef.current.validateCardFields();
					if (!isValid) {
						cvcRevalidateRef.current.focusOnCvc();
					}
				}
			} else {
				const fields = [cardNumber, cvc, expirationDate, zipCode];
				for (const field of fields) {
					if (field.error) {
						document.getElementById(`${field.id}-input`)?.focus();
						document
							.getElementById(`${field.id}-input`)
							?.scrollIntoView({ behavior: 'smooth', block: 'center' });
						break;
					}
				}
			}
			setTimeout(() => {
				const focusedElement = document.activeElement;
				if (focusedElement) {
					focusedElement.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
				}
			}, 100);
		},
	}));

	useEffect(() => {
		initBillingScheme();
	}, [giftCardState, basket?.discount, basketEmptyValue, basket?.deliverymode]);

	useEffect(() => {
		SetShowMsgDigitalWallets(supportsOlopay);
	}, [supportsOlopay]);

	useEffect(() => {
		if (cvc.value || cardNumber.value || expirationDate.value || zipCode.value) {
			if (!formStarted) {
				setFormStarted(true);
			}
		}
	}, [cvc.value, cardNumber.value, expirationDate.value, zipCode.value]);

	useEffect(() => {
		if (selectPaymentOption.type !== PaymentType.Default) {
			setDropdownError('');
		}
		if (selectPaymentOption.type === PaymentType.AddACard) {
			setExpandCardFields(true);
			return;
		}
		if ([PaymentType.GooglePay, PaymentType.ApplePay].includes(selectPaymentOption.type)) {
			setExpandDigitalWallets(true);
			return;
		}
		setExpandCardFields(false);
		setExpandDigitalWallets(false);
	}, [selectPaymentOption]);

	useEffect(() => {
		if (expandCardFields) {
			const element = document.getElementById('card-number-input');
			if (shouldFocusOnPayment) {
				element?.focus();
			} else {
				setShouldFocusOnPayment(true);
			}
		}
	}, [expandCardFields]);

	const canPayWithSelectedMethod = (paymentOptions: BillingScheme[], paymentType: BillingMethods) => {
		return paymentOptions.some((option) => {
			return option.type === paymentType;
		});
	};

	const validatePaymentMethod = () => {
		if (!basketEmptyValue && selectPaymentOption.type === PaymentType.Default) {
			setDropdownError('Select a payment method');
			return false;
		}
		let method;

		switch (selectPaymentOption.type) {
			case PaymentType.AddACard:
				method = BillingMethods.CreditCard;
				setBillingMethod(BillingMethods.CreditCard);
				break;
			case PaymentType.PayAtRestaurant:
				method = BillingMethods.Cash;
				setBillingMethod(BillingMethods.Cash);
				break;
			case PaymentType.Amex:
			case PaymentType.Discover:
			case PaymentType.Mastercard:
			case PaymentType.Visa:
				method = BillingMethods.BillingAccount;
				setBillingMethod(BillingMethods.BillingAccount);
				setSelectedBillingAccountId(selectPaymentOption.id!);
				break;
			case PaymentType.ApplePay:
			case PaymentType.GooglePay:
				method = BillingMethods.DigitalWallet;
				setBillingMethod(BillingMethods.DigitalWallet);
				break;
		}
		if (method === BillingMethods.CreditCard) {
			if (!supportsOlopay) {
				let hasBlankField: boolean = false;
				if (!cardNumber.value) {
					cardNumber.setError(ValidationCreditCard.insertCardNumber);
					hasBlankField = true;
				}
				if (!expirationDate.value) {
					expirationDate.setError(ValidationCreditCard.insertExpirationDate);
					hasBlankField = true;
				}
				if (!cvc.value) {
					cvc.setError(ValidationCreditCard.insertCvc);
					hasBlankField = true;
				}
				if (!zipCode.value) {
					zipCode.setError(ValidationCreditCard.insertZipCode);
					hasBlankField = true;
				}
				if (hasBlankField) {
					setLoading(false);
					return false;
				}
			} else {
				return creditCardPaymentRef.current?.validateCardFields() ?? false;
			}
		} else if (method === BillingMethods.BillingAccount && supportsOlopay) {
			return doCVVRevalidation() ? cvcRevalidateRef.current?.validateCardFields() ?? false : true;
		}

		if (cardNumber.error || expirationDate.error || cvc.error || zipCode.error) {
			return false;
		}
		return true;
	};

	const removeBillingAccount = async (billingAccountId?: string) => {
		if (!billingAccountId) {
			return;
		}

		const { error } = await UserManager.RemoveUserBillingAccount(userToken!, billingAccountId);
		if (error) {
			setToast({
				variant: 'error',
				text: error,
			});
			GaEvent.ErrorMessage(error, pageTitle);
		}

		setPaymentOptions(paymentOptions.filter((p) => p.id !== billingAccountId));
		setLoading(false);
	};

	const handleRepeatLastPaymentType = (paymentOptions: PaymentOption[]) => {
		const option = paymentOptions.find((option) => option.type === lastOrderPayment);

		if (lastOrderPayment && option) {
			onHandleChangePaymentType(lastOrderPayment);
			setSelectPaymentOption(option);
			setShouldFocusOnPayment(false);
		}
	};

	const initBillingScheme = async () => {
		setLoading(true);

		const { response, error } = await CheckoutManager.GetBillingScheme(basket!.id);

		if (!response || error) {
			const errorMessage = error ?? getBillingSchemeError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});

			setDropdownError(unavailablePaymentMethods);
			setUnavailableBillingScheme(true);
			GaEvent.ErrorMessage(errorMessage, pageTitle);
			setLoading(false);
			return;
		}

		setBillingScheme(response);
		setBillingSchemeConst(response);

		const paymentOptions: Array<PaymentOption> = [paymentTypeDefault[0]];

		if (canPayWithSelectedMethod(response, BillingMethods.CreditCard)) {
			paymentOptions.push({ type: PaymentType.AddACard, label: 'Add a card' });
			setsupportsOlopay(response.find((r) => r.type === BillingMethods.CreditCard)?.supportsolopay ?? false);
		}

		const billingSchemeForApplePay = response.find(
			(b) => b.supportsolopay && b.name === DigitalWalletsPaymentOptions.ApplePay
		);

		const billingSchemeForGooglePay = response.find(
			(b) => b.supportsolopay && b.name === DigitalWalletsPaymentOptions.GooglePay
		);

		if (billingSchemeForApplePay || billingSchemeForGooglePay) {
			const { supportsApplePay, supportsGooglePay } = await supportsDigitalWallets();
			if (supportsApplePay) {
				paymentOptions.push({ type: PaymentType.ApplePay, label: DigitalWalletsPaymentOptions.ApplePay });
			}
			if (supportsGooglePay) {
				paymentOptions.push({ type: PaymentType.GooglePay, label: DigitalWalletsPaymentOptions.GooglePay });
			}
		}

		if (
			basket?.deliverymode !== HandoffMethods.Delivery &&
			!giftCardState.length &&
			response &&
			canPayWithSelectedMethod(response, BillingMethods.PayInStore)
		) {
			paymentOptions.push({ type: PaymentType.PayAtRestaurant, label: 'Pay at restaurant' });
		}

		const _billingAccounts = response.flatMap((scheme) => {
			if (scheme.accounts && scheme.accounts.length > 0) {
				return scheme.accounts.map((account) => {
					return account;
				});
			} else {
				return [];
			}
		});

		if (canPayWithSelectedMethod(response, BillingMethods.CreditCard) && _billingAccounts?.length > 0) {
			_billingAccounts?.forEach((b) => {
				if (b.accounttype === BillingMethods.CreditCard) {
					switch (b.cardtype) {
						case 'Amex': {
							const amex: PaymentOption = {
								type: PaymentType.Amex,
								label: b.cardsuffix,
								id: b.accountidstring,
								removable: true,
							};
							paymentOptions.push(amex);
							break;
						}
						case 'Mastercard': {
							const mastercard: PaymentOption = {
								type: PaymentType.Mastercard,
								label: b.cardsuffix,
								id: b.accountidstring,
								removable: true,
							};
							paymentOptions.push(mastercard);
							break;
						}
						case 'Visa': {
							const visa: PaymentOption = {
								type: PaymentType.Visa,
								label: b.cardsuffix,
								id: b.accountidstring,
								removable: true,
							};
							paymentOptions.push(visa);
							break;
						}
						case 'Discover': {
							const discover: PaymentOption = {
								type: PaymentType.Discover,
								label: b.cardsuffix,
								id: b.accountidstring,
								removable: true,
							};
							paymentOptions.push(discover);
							break;
						}
					}
				}
			});
		}

		if (
			basketEmptyValue ||
			selectPaymentOption.label === 'Payment method not required' ||
			!paymentOptions.some((o) => o.type === selectPaymentOption.type)
		)
			setSelectPaymentOption(paymentTypeDefault[0]);
		else {
			if (paymentOptions.find((o) => o.type === selectPaymentOption.type)) {
				setSelectPaymentOption(paymentOptions.find((o) => o.type === selectPaymentOption.type)!);
			}
		}

		setPaymentOptions(paymentOptions);

		if (!basketEmptyValue && paymentOptions.length === 2) {
			onHandleChangePaymentType(paymentOptions[1].type);
			setSelectPaymentOption(paymentOptions[1]);
			setShouldFocusOnPayment(false);
		} else {
			handleRepeatLastPaymentType(paymentOptions);
		}

		setLoading(false);
	};

	const handleDigitalWalletPayment = (method: PaymentMethod) => {
		onHandleDigitalWalletsPayment(method);
	};

	const handleDigitalWalletPaymentError = () => {
		onHandleDigitalWalletsPaymentError();
	};

	const handleChangePayment = (paymentOption: PaymentOption) => {
		onHandleChangePaymentType(paymentOption.type);
		setSelectPaymentOption(paymentOption);
	};

	const doCVVRevalidation = (): boolean => {
		if (!supportsOlopay) return false;
		const response = billingSchemeConst;

		if (!response || response.length === 0) {
			return false;
		}
		const billingMethodCard = response.find((r) => r.billingsettings.find((b) => b.name === 'DoCvvRevalidation'));
		const billingSettingsValue = billingMethodCard?.billingsettings.find((b) => b.name === 'DoCvvRevalidation');

		if (!billingSettingsValue) return false;

		return (
			[PaymentType.Amex, PaymentType.Mastercard, PaymentType.Discover, PaymentType.Visa].includes(
				selectPaymentOption.type
			) && billingSettingsValue?.value !== 'Never'
		);
	};

	return (
		<Styled.Container data-testid='payment-info-component'>
			<Styled.Section>
				<Styled.SectionTitle>Payment</Styled.SectionTitle>
				<CustomDropdown
					disabled={basketEmptyValue || unavailableBillingScheme}
					items={paymentOptions
						.filter((o) => !(o.type === selectPaymentOption.type && o.label === selectPaymentOption.label))
						.map((o, i) => (
							<PaymentTile
								type={o.type}
								label={o.label}
								key={i}
								canRemove={o.removable && selectPaymentOption.type !== o.type}
								onRemove={() => removeBillingAccount(o.id)}
								onClick={() => handleChangePayment(o)}
							/>
						))}
					selected={
						<PaymentTile
							type={selectPaymentOption.type}
							label={selectPaymentOption.label}
							canRemove={false}
							onRemove={() => removeBillingAccount(selectPaymentOption.id)}
						/>
					}
					error={dropdownError}
				/>

				{doCVVRevalidation() && (
					<>
						<Styled.RevalidateCVCSection id='cvc-revalidate' data-testid='cvc-revalidate'>
							<Styled.SectionTitle>Confirm your CVC</Styled.SectionTitle>
							<CvcRevalidate ref={cvcRevalidateRef} />
						</Styled.RevalidateCVCSection>
					</>
				)}

				{selectPaymentOption.type === PaymentType.PayAtRestaurant && (
					<WarningMessage variant='info' message={messageTipAtRestaurant} />
				)}
			</Styled.Section>

			{expandDigitalWallets && (
				<DigitalWalletsPayment
					onPayment={handleDigitalWalletPayment}
					onPaymentError={handleDigitalWalletPaymentError}
					handleDeliveryInstructions={handleDeliveryInstructions}
					handleBasketCustomFields={handleBasketCustomFields}
					validateBasket={validateBasket}
					setLoading={setLoading}
					ref={digitalWalletsRef}
					paymentValue={paymentValue}
				/>
			)}

			{expandCardFields && (
				<>
					<Styled.Line />
					<Styled.Section id='card-information' data-testid='card-information'>
						<Styled.SectionTitle>Card Information</Styled.SectionTitle>
						{supportsOlopay ? (
							<CreditCardPayment ref={creditCardPaymentRef} />
						) : (
							<Styled.AddCreditCard>
								<Input handler={cardNumber} mode={InputModes.Numeric} />
								<Input handler={cvc} mode={InputModes.Numeric} />
								<Input handler={expirationDate} mode={InputModes.Numeric} />
								<Input handler={zipCode} mode={InputModes.Numeric} onEnter={onHandleBasketSubmition} />
							</Styled.AddCreditCard>
						)}

						{isAuthenticated && (
							<Styled.SaveCard>
								<Checkbox
									checked={saveCard}
									onClick={() => setSaveCard(!saveCard)}
									label='Save card to account'
									id='save-card'
								/>
							</Styled.SaveCard>
						)}
					</Styled.Section>
				</>
			)}

			{showMsgDigitalWallets && <WarningMessage variant='info' message={messageDigitalWallet} />}
		</Styled.Container>
	);
});
