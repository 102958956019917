import { useEffect } from 'react';
import { Restaurant, SearchedAddresses } from '@maverick/entity';
import * as Styled from './CurbsideHandoff.styled';
import { useTheme } from 'styled-components';
import { RestaurantCard } from '../../../../../shared/components/RestaurantCard/index';
import { Icon, useModal } from '@maverick/ui';
import { checkAllHandoffMethodsAvailability } from '@maverick/utils';

interface CurbsideContentProps {
	restaurantsList: Restaurant[];
	curbsideRestaurant: Restaurant | null;
	searchedAddresses: SearchedAddresses | null;
	handleHighlightedRestaurant: (restaurant: Restaurant) => void;
}

export const CurbsideContent = ({
	restaurantsList,
	curbsideRestaurant,
	searchedAddresses,
	handleHighlightedRestaurant,
}: CurbsideContentProps) => {
	const { orderUi } = useTheme();
	const { setModal } = useModal();

	useEffect(() => {
		const hasAnyRestaurantOpen = (restaurants: Restaurant[]) => {
			if (
				restaurantsList.length > 0 &&
				!restaurants.some((restaurant) => checkAllHandoffMethodsAvailability(restaurant))
			) {
				setModal({
					id: 'no-restaurant-modal',
					title: 'HEADS UP MATE!',
					text: 'We currently have no restaurants open.\nPlease try again later.',
					primaryButton: {
						label: 'Close',
						onClick: () => {},
					},
				});
			}
		};
		hasAnyRestaurantOpen(restaurantsList);
	}, [restaurantsList]);

	const renderRestaurantList = () => {
		return (
			<Styled.RestaurantContainer role='listbox' data-testid={'restaurant-list'} aria-label='restaurant list'>
				{restaurantsList.map((restaurant, index) => {
					// TODO: Check for Removal in February 2024
					if (restaurant.id !== 225934) {
						return (
							<RestaurantCard
								key={restaurant.id}
								restaurant={restaurant}
								selected={curbsideRestaurant?.id === restaurant.id}
								onClick={() => handleHighlightedRestaurant(restaurant)}
								expandItems='More info'
								listIndex={index}
								searchedAddresses={searchedAddresses}
							/>
						);
					}
				})}
			</Styled.RestaurantContainer>
		);
	};

	const renderNotFound = () => {
		return (
			<Styled.NotFound>
				<Styled.FoundImage>
					<Icon icon={orderUi.icons.iconNoRestaurantsFound} customSize />
				</Styled.FoundImage>
				<Styled.MainText tabIndex={0}>Search for a location to check the menu.</Styled.MainText>
				<Styled.SubText tabIndex={0}>
					Enable your location to find the nearest {orderUi.app.shortName}.
				</Styled.SubText>
			</Styled.NotFound>
		);
	};

	return restaurantsList.length > 0 ? renderRestaurantList() : renderNotFound();
};
