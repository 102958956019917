import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Redeem = styled.button`
	font-family: unset;
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	padding: 0;
	background: none;
	border: none;
	margin: 0;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.light};
	}

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.button}
		margin-top: 16px;
	}
`;

export const RedeemContent = styled.div`
	width: 100%;
	padding: 20px 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: max-content;
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const RedeemItems = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${DESKTOP_BREAKPOINT}px) {
		flex-direction: row;
	}
`;

export const Icon = styled.div`
	height: 20px;
	margin-right: 16px;
	align-items: center;
	justify-content: center;
	width: 20px;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		margin-right: 10px;
		height: 20px;
	}
`;

export const RedeemColumn = styled.div`
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	flex: 1;
`;

export const RewardsInfo = styled.div`
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.button}
	}

	& > span {
		color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	}
`;

export const InfoSpan = styled.span`
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.button}
	}
`;
