import { FC, RefObject } from 'react';
import * as Styled from './CategoryPill.styled';
import { handleKeyDown } from '../../../utils/GeneralUtils';
import { useTheme } from 'styled-components';

export interface PillProps {
	text: string;
	selected?: boolean;
	id: string;
	pillRef: RefObject<HTMLAnchorElement>;
	onClick: () => void;
	metadata: Array<{ key: string; value: string }>;
}

const APP_ONLY = { key: 'category-highlight-display', value: 'app-only-category' };

export const Pill: FC<PillProps> = ({ text, selected, id, onClick, pillRef, metadata }) => {
	const cleanedText = text.trim();

	const { orderUi } = useTheme();

	const handleMetadataIcon = (metadata: Array<{ key: string; value: string }>) => {
		if (!metadata) {
			return '';
		}

		if (metadata.find((metadata) => metadata?.key === APP_ONLY.key)?.value === APP_ONLY.value) {
			return <Styled.AppOnlyIcon data-testid='appOnlyCategory-Icon' icon={orderUi.icons.iconAppOnlyCategory} />;
		}

		return '';
	};

	return (
		<Styled.Container
			id={id}
			selected={selected}
			onClick={onClick}
			onKeyDown={(ev) => handleKeyDown(ev, onClick)}
			role='menuitem'
			tabIndex={0}
			aria-label={`Scroll to ${cleanedText}`}
			aria-current={selected ? 'true' : 'false'}
			data-testid={`pill-container-${cleanedText.replace(/[^a-zA-Z0-9]/g, '')}`}
			ref={pillRef}
		>
			<Styled.Pill data-testid={`pill-${cleanedText}`}>
				{handleMetadataIcon(metadata)}
				{cleanedText}
			</Styled.Pill>
		</Styled.Container>
	);
};
