import { HttpMethods, RestRequest, RestResponse } from '@maverick/http';
import { DateWithoutUTCFormat } from '@maverick/utils';
import { OloClient } from './client';

const searchRadiusMiles = 50;
const conceptId = String(process.env.CONCEPT_ID);

interface GetNearRestaurantsParams {
	latitude: number;
	longitude: number;
	calendarstart: string;
	calendarend: string;
}

interface GetRestaurantsParams {
	latitude: number;
	longitude: number;
	calendarstart: string;
	calendarend: string;
}

interface GetRestaurantCalendarParams {
	restaurantId: number;
}

interface CheckIfRestaurantCanDeliverParams {
	id: string;
	handoffmode: string;
	timewantedmode: string;
	timewantedutc: string;
	street: string;
	city: string;
	zipcode: string;
}

interface GetRestaurantParams {
	restaurantId: number;
}

export const RestaurantsService = {
	GetNearRestaurants: async (params: GetNearRestaurantsParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/v1.1/restaurants/near?lat=${params.latitude}&long=${params.longitude}&radius=${searchRadiusMiles}&limit=10&calendarstart=${params.calendarstart}&calendarend=${params.calendarend}`,
			retry: true,
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetDeliveryRestaurant: async (params: GetRestaurantsParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/v1.1/restaurants/deliveringto?lat=${params.latitude}&long=${params.longitude}&limit=1&calendarstart=${params.calendarstart}&calendarend=${params.calendarend}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetRestaurantCalendar: async ({ restaurantId }: GetRestaurantCalendarParams): Promise<RestResponse> => {
		const today = DateWithoutUTCFormat(new Date());
		const plus6days: Date = new Date();
		plus6days.setDate(today.getDate() + 6);

		const convertDateToString = (date: Date) => {
			return date.toISOString().slice(0, 10).replaceAll('-', '');
		};

		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/v1.1/restaurants/${restaurantId}/calendars?from=${convertDateToString(
				today
			)}&to=${convertDateToString(plus6days)}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	CheckIfRestaurantCanDeliver: async (params: CheckIfRestaurantCanDeliverParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `/v1.1/restaurants/${params.id}/checkdeliverycoverage`,
			body: {
				handoffmode: params.handoffmode,
				timewantedmode: params.timewantedmode,
				timewantedutc: params.timewantedutc,
				street: params.street,
				city: params.city,
				zipcode: params.zipcode,
			},
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetRestaurant: async ({ restaurantId }: GetRestaurantParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/v1.1/restaurants/${restaurantId}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetRestaurantBySlug: async (slug: string): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/v1.1/restaurants/byslug/${slug}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetLocationByRef: async (ref: string): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/v1.1/restaurants/byref/${conceptId}_${ref}`,
		});

		return await OloClient.ExecuteRequest(request);
	},
} as const;
