import { RootState } from '../../../../infrastructure';
import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';
import { connect, ConnectedProps } from 'react-redux';
import { MenuOperations } from '../../redux/Menu.operations';
import { CustomizeProduct } from './CustomizeProduct';
import { AppOperations } from '../../../App/redux/App.operations';
import { CheckoutOperations } from '../../../Checkout/redux/Checkout.operations';
import { HandoffOperations } from '../../../HandoffMethod/redux/Handoff.operations';

export * from './CustomizeProduct';

const mapStateToProps = (state: RootState) => ({
	optionGroups: state.menu.optionGroups,
	selectedProduct: state.menu.selectedProduct,
	selectedOptions: state.menu.selectedOptions,
	loading: state.app.loading,
	processing: state.app.processing,
	imageBasePath: state.menu.menu?.imagepath,
	basket: state.checkout.basket,
	editedProduct: state.menu.editedProduct,
	menu: state.menu.menu,
	selectedRestaurant: state.handoff.selectedRestaurant,
	rawOptionGroups: state.menu.rawOptionGroups,
	redirectRouteMenu: state.menu.redirectRouteMenu,
	skeletonPages: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.SkeletonPages),
	userToken: state.app.userToken,
	deliveryAddress: state.handoff.deliveryAddress,
	method: state.handoff.selectedMethod,
	authToken: state.app.userToken,
	userInfo: state.user.userInfo,
	addCartOrigin: state.menu.addCartOrigin,
});

const mapDispatchToProps = {
	setLoading: AppOperations.setLoading,
	setProcessing: AppOperations.setProcessing,
	setRedirectRouteEdit: AppOperations.setRedirectRouteEdit,
	
	setEditedProduct: MenuOperations.setEditedProduct,
	setOptionGroups: MenuOperations.setOptionGroups,
	setSelectedProduct: MenuOperations.setSelectedProduct,
	setEditedOptions: MenuOperations.setEditedOptions,
	resetCustomize: MenuOperations.resetCustomize,
	setAddCartOrigin: MenuOperations.setAddCartOrigin,

	setBasket: CheckoutOperations.setBasket,
	setBasketCreationDate: CheckoutOperations.setBasketCreationDate,
	setDeliveryAddress: HandoffOperations.setDeliveryAddress,
	setMethod: HandoffOperations.setSelectedMethod,
	setRedirectRouteMenu: MenuOperations.setRedirectRouteMenu,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const CustomizeProductConnected = connector(CustomizeProduct);
export { CustomizeProductConnected as CustomizeProduct };
