import styled, { css } from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../utils/GeneralUtils';
import { RestaurantCardProps } from './RestaurantCard';

export const Container = styled.div<Pick<RestaurantCardProps, 'selected'>>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px 16px 32px;
	border-radius: 4px;
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	cursor: pointer;
	border: 1px solid ${({ theme }) => theme.orderUi.colors.secondary.dark};
	position: relative;
	margin: 1px;

	${({ selected }) =>
		selected &&
		css`
			border: 2px solid ${({ theme }) => theme.orderUi.colors.secondary.dark};
			border-color: ${({ theme }) => theme.orderUi.colors.primary.variant};
			margin: 0px;
		`}
`;

export const LocationContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const Location = styled.div``;

export const IconContainer = styled.div<{ isAvailable: boolean }>`
	width: 40px;

	${({ isAvailable }) =>
		isAvailable &&
		css`
			color: ${({ theme }) => theme.orderUi.colors.secondary.darkVariant};
		`}

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: 48px;
	}
`;

export const HoursContainer = styled.div`
	margin-top: 16px;
	display: grid;
	gap: 8px;
	grid-template-rows: auto max-content;
`;

export const AlertContainer = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	margin-top: 8px;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.inputText};
	}
`;

export const MoreInfo = styled.div`
	position: absolute;
	right: 16px;
	bottom: 8px;
`;

export const Directions = styled.div`
	position: absolute;
	right: 96px;
	bottom: 8px;
`;

export const ExpandedItems = styled.div``;

export const SectionTitle = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	margin-bottom: 16px;
`;

export const Title = styled.span`
	display: inline-block;
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const IconTitle = styled.span`
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	display: inline-block;
	height: 16px;
	width: 16px;
`;

export const SectionContent = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	${({ theme }) => theme.orderUi.typography.caption.caption};
`;

export const LessInfo = styled.div`
	position: absolute;
	right: 16px;
	bottom: 8px;
`;

export const Line = styled.hr`
	background-color: ${({ theme }) => theme.orderUi.colors.neutral.medium};
	border: none;
	height: 1.5px;
	margin: 16px 0;
`;

export const RestaurantHours = styled.div`
	display: grid;
	grid-template-columns: 40px max-content;
`;

export const ContactUs = styled.a``;

export const Weekday = styled.div`
	grid-column: 1;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;
export const BusinessHours = styled.div<{ hours: string }>`
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	${({ hours }) =>
		hours === 'Closed' &&
		css`
			color: ${({ theme }) => theme.orderUi.colors.primary.medium};
		`}
	grid-column: 2;
`;

export const CurrentClosedContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;
`;

export const CurrentClosedText = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	margin-left: 8px;
	line-height: unset;
`;

export const IconWarningContainer = styled.div`
	width: 14px;
	height: 14px;
`;
