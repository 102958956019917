import styled, { css } from 'styled-components';
import { OptionsCardProps } from './OptionsCard';

export const Container = styled.div<Pick<OptionsCardProps, 'selected'>>`
	display: flex;
	flex-direction: column;
	position: relative;

	${({ selected }) =>
		selected &&
		css`
			${Content} {
				border-color: ${({ theme }) => theme.orderUi.colors.primary.variant};
			}

			${IconSelected} {
				display: block;
			}

			${Description} {
				${({ theme }) => theme.orderUi.typography.caption.captionMedium};
				color: ${({ theme }) => theme.orderUi.colors.primary.variant};
			}
		`}
`;

export const Content = styled.div`
	flex: 1;
	border: 1.5px solid ${({ theme }) => theme.orderUi.colors.tertiary.medium};
	border-radius: 4px;
	padding: 12px;
	text-align: center;
	cursor: pointer;
`;

export const IconSelected = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.primary.variant};
	width: 10px;
	height: 10px;
	position: absolute;
	top: 4px;
	right: 4px;
	display: none;

	img {
		position: absolute;
		width: 100%;
		height: 100%;
	}
`;

export const ImageContainer = styled.div`
	border-radius: 2px;
	aspect-ratio: 70 / 35;
`;

export const Description = styled.div`
	margin: 8px 0 0;
	${({ theme }) => theme.orderUi.typography.caption.caption};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Cost = styled.div`
	height: 18px;
	margin: 8px 0 0;
	text-align: center;
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;
