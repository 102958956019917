import { Restaurant } from '@maverick/entity';
import React, { FC } from 'react';
import * as Styled from './RestauranteInfo.styled';
import { handleRestaurantInfo } from '@maverick/utils';

export interface RestaurantInfoProps {
	restaurant: Restaurant;
	listIndex?: number;
}

export const RestaurantInfo: FC<RestaurantInfoProps> = ({ restaurant, listIndex = 0 }) => {
	const { restaurantName, restaurantAddress } = handleRestaurantInfo(restaurant);

	return (
		<Styled.Container data-testid='restaurant-info'>
			<Styled.HeaderContainer>
				<Styled.Title data-testid={`restaurant-name-${listIndex}`}>{restaurantName}</Styled.Title>
				<Styled.DotContainer>•</Styled.DotContainer>
				<Styled.Distance>{`${restaurant.distance} mi`}</Styled.Distance>
			</Styled.HeaderContainer>
			<Styled.Adress data-testid={`restaurant-address-${listIndex}`}>{restaurantAddress}</Styled.Adress>
		</Styled.Container>
	);
};
