import {
	Basket,
	BasketDeliveryAddress,
	BasketValidation,
	Item,
	TransferBasket,
	UpsellGroup,
	CustomField,
	GiftCard,
	BillingScheme,
	RetrieveGiftCardBalance,
	TimeWanted,
	SubmitWithSinglePayment,
	SubmitWithMultiplePayments,
	OrderReceived,
	OloApiError,
	ApplyRewards,
	BillingAccount,
	CreateFromOrderBody,
	HandoffMethods,
} from '@maverick/entity';
import { HttpStatus } from '@maverick/http';
import { CheckoutService } from '../../services/olo/Checkout.service';
import { getBillingAccountsError } from '../../shared/constants';
import { Log } from '../../infrastructure/logger/Logger';

export const CheckoutManager = {
	SetBasket: async (
		vendorid: number,
		authtoken: string | null
	): Promise<Partial<{ basket: Basket; error: string }>> => {
		const response = await CheckoutService.SetBasket({ vendorid, authtoken });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { basket: basket };
	},

	GetBasket: async (basketId: string): Promise<Partial<{ retrieveBasket: Basket; error: string }>> => {
		const response = await CheckoutService.GetBasket({ basketId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { retrieveBasket: basket };
	},

	SetBasketDeliveryAddress: async (
		basketId: string,
		basketDeliveryAddress: BasketDeliveryAddress
	): Promise<Partial<{ settedAddressToBasket: Basket; setBasketDeliveryAddressError: string }>> => {
		const response = await CheckoutService.SetBasketDeliveryAddress({ basketId, basketDeliveryAddress });
		if (response.error) {
			return { setBasketDeliveryAddressError: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { settedAddressToBasket: basket };
	},

	SetBasketHandoffMethod: async (
		basketId: string,
		deliverymode: HandoffMethods
	): Promise<Partial<{ settedHandoffToBasket: Basket; setHandoffBasketError: string }>> => {
		const response = await CheckoutService.SetBasketHandoffMethod({ basketId, deliverymode });
		if (response.error) {
			return { setHandoffBasketError: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { settedHandoffToBasket: basket };
	},

	SetTransferBasket: async (
		basketId: string,
		vendorid: number
	): Promise<Partial<{ transferBasket: TransferBasket; error: string }>> => {
		const response = await CheckoutService.SetTransferBasket({ basketId, vendorid });
		if (response.error) {
			const logObject = {
				handoffMethod: response?.body?.basket?.deliverymode,
				newRestaurant: vendorid,
				basketId,
			};
			Log.Error('BasketTransfer', logObject);
			return { error: response.errorMessage };
		}
		const transferBasket = TransferBasket.Make(response.body);
		return { transferBasket: transferBasket };
	},

	GetUpsells: async (basketId: string): Promise<Partial<{ groups: Array<UpsellGroup>; error: string }>> => {
		const response = await CheckoutService.GetUpsells({ basketId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const groups: Array<UpsellGroup> = response.body.groups.map((u: any) => UpsellGroup.Make(u));
		return { groups: groups };
	},

	AddUpsellItems: async (basketId: string, items: Item): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.AddUpsellItems({ basketId, items });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	DeleteProductFromBasket: async (
		basketId: string,
		productId: number
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.DeleteProductFromBasket({ basketId, productId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	DeleteAllProductFromBasket: async (
		basketId: string,
		productIds: Array<number>
	): Promise<Partial<{ response: Basket | undefined; error: boolean }>> => {
		let error: boolean = false;

		let basket: Basket | undefined;

		await Promise.all(
			productIds.map(async (productId) => {
				const response = await CheckoutService.DeleteProductFromBasket({ basketId, productId });

				if (response.error) {
					error = true;
				}
			})
		);

		const response = await CheckoutService.GetBasket({ basketId });

		if (!response.error) {
			basket = Basket.Make(response.body);
		}

		return { response: basket, error: error };
	},

	SetBasketCustomFieldValue: async (
		basketId: string,
		customField: CustomField
	): Promise<Partial<{ retrieveBasket: Basket; error: string }>> => {
		const response = await CheckoutService.SetBasketCustomFieldValue({ basketId, customField });

		if (response.error) {
			return { error: response.errorMessage };
		}

		const basket = Basket.Make(response.body);
		return { retrieveBasket: basket };
	},

	SetTipAmount: async (
		basketId: string,
		amount: number
	): Promise<Partial<{ response: Basket; error: OloApiError }>> => {
		const response = await CheckoutService.SetTipAmount({ basketId, amount });

		if (response.error) {
			if (response.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(response.body);
				return { error: error };
			}

			return {
				error: {
					num: response?.body?.num ?? 0,
					message: 'An error occurred while applying your tip. Please try again.',
				},
			};
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	SetTimeWanted: async (
		basketId: string,
		timeWanted: TimeWanted
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.SetTimeWanted({ basketId, timeWanted });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	SetBasketTimeModetoASAP: async (basketId: string): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.SetBasketTimeModetoASAP({ basketId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	GetBillingScheme: async (basketId: string): Promise<Partial<{ response: Array<BillingScheme>; error: string }>> => {
		const response = await CheckoutService.GetBillingScheme({ basketId });
		if (response.error) {
			return { error: response.errorMessage };
		}

		const billingScheme: Array<BillingScheme> = response.body.billingschemes.map((u: unknown) =>
			BillingScheme.Make(u)
		);
		return { response: billingScheme };
	},

	PostRetrieveGiftCardBalance: async (
		basketId: string,
		billingSchemeId: number,
		giftCard: GiftCard
	): Promise<Partial<{ retrieveGiftCardBalance: RetrieveGiftCardBalance; error: string }>> => {
		const response = await CheckoutService.PostRetrieveGiftCardBalance({
			basketId,
			billingSchemeId,
			giftCard,
		});
		if (response.error) {
			return { error: response.errorMessage };
		}

		const retrieveGiftCardBalance = RetrieveGiftCardBalance.Make(response.body);
		return { retrieveGiftCardBalance };
	},

	AddPromoCode: async (
		basketId: string,
		promoCode: string
	): Promise<Partial<{ promoCodeBasket: Basket; error: string }>> => {
		const response = await CheckoutService.AddPromoCode({ basketId, promoCode });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { promoCodeBasket: basket };
	},

	RemovePromoCode: async (basketId: string): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.RemovePromoCode({ basketId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	SubmitOrderWithSinglePayment: async (
		basketId: string,
		submitSinglePayment: SubmitWithSinglePayment
	): Promise<Partial<{ response: OrderReceived; error: OloApiError }>> => {
		const response = await CheckoutService.SubmitOrderWithSinglePayment({ basketId, submitSinglePayment });
		if (response.error) {
			if (response.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(response.body);
				return { error: error };
			}

			return {
				error: {
					num: response?.body?.num ?? 0,
					message: 'An error occurred while submiting your order. Please try again later.',
				},
			};
		}
		const orderReceived = OrderReceived.Make(response.body);
		return { response: orderReceived };
	},

	SubmitOrderWithMultiplePayments: async (
		basketId: string,
		submitMultiplePayments: SubmitWithMultiplePayments
	): Promise<Partial<{ response: OrderReceived; error: OloApiError }>> => {
		const response = await CheckoutService.SubmitOrderWithMultiplePayments({ basketId, submitMultiplePayments });
		if (response.error) {
			if (response.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(response.body);
				return { error: error };
			}

			return {
				error: {
					num: response?.body?.num ?? 0,
					message: 'An error occurred while submiting your order. Please try again later.',
				},
			};
		}
		const orderReceived = OrderReceived.Make(response.body);
		return { response: orderReceived };
	},

	ValidateBasket: async (
		basketId: string
	): Promise<Partial<{ basketValidation: BasketValidation; error: OloApiError }>> => {
		const response = await CheckoutService.ValidateBasket({ basketId });
		if (response.error) {
			if (response.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(response.body);
				return { error: error };
			}

			return {
				error: { num: response?.body?.num ?? 0, message: 'An error occurred while validating the basket.' },
			};
		}

		const validationResponse = BasketValidation.Make(response.body);
		return { basketValidation: validationResponse };
	},

	AddRewards: async (
		basketId: string,
		applyRewardsToBasket: ApplyRewards
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.ApplyToRewardsToBasket({ basketId, applyRewardsToBasket });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	RemoveRewards: async (
		basketId: string,
		rewardId: number
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.RemoveRewards({ basketId, rewardId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},

	GetUserBillingAccounts: async (
		userToken: string
	): Promise<Partial<{ accountResponse: BillingAccount; accountError?: OloApiError }>> => {
		const accountResponse = await CheckoutService.GetUserBillingAccount({ userToken });
		if (accountResponse.error) {
			if (accountResponse.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(accountResponse.body);
				return { accountError: error };
			}

			return {
				accountError: {
					num: accountResponse?.body?.num ?? 0,
					message: getBillingAccountsError,
				},
			};
		}
		const billingAccounts = BillingAccount.Make(accountResponse.body);
		return { accountResponse: billingAccounts };
	},

	CreateFromOrder: async (
		userToken: string,
		body: CreateFromOrderBody
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await CheckoutService.CreateFromOrder({ userToken, body });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const basket = Basket.Make(response.body);
		return { response: basket };
	},
};
