import { Basket, Brands, BrandsNames, ConceptIds, Rewards, RewardWallet } from '@maverick/entity';
import { CheckoutManager } from '../features/Checkout/Checkout.manager';
import { GaEvent } from '@maverick/utils';
import { retrieveBasketError } from '../shared/constants';

export const filterRewards = (rewards: RewardWallet[], days: number) => {
	const currentDate = new Date();
	const expirationDate = new Date();

	expirationDate.setDate(currentDate.getDate() + days);

	const result = rewards.filter((reward) => {
		const rewardExpirationDate = new Date(reward.expirationDate);
		return rewardExpirationDate <= expirationDate;
	});

	return result;
};

const brandMapping: { [key in Brands]: ConceptIds } = {
	[Brands.Outback]: 'OBS',
	[Brands.Carrabbas]: 'CIG',
	[Brands.Bonefish]: 'BFG',
	[Brands.Flemings]: 'FPS',
};

export interface RewardsResult {
	redeemable: RewardWallet[];
	notRedeemable: RewardWallet[];
	extra: RewardWallet[]; // TODO REMOVE extra
}

export function getRewardsByBrand(rewards: RewardWallet[], brand: Brands): RewardsResult {
	const result: RewardsResult = { redeemable: [], notRedeemable: [], extra: [] }; // TODO REMOVE extra
	const conceptId = brandMapping[brand];
	const totalBrands = Object.keys(Brands).length;

	rewards.forEach((reward) => {
		const rewardCopy = { ...reward, validAt: Array<string>() };

		// TODO REMOVE this condition and its content
		if (!rewardCopy.concepts || !rewardCopy.concepts.length) {
			result.extra.push(rewardCopy);
			result.redeemable.push(rewardCopy);
			return;
		}

		if (rewardCopy.concepts.length === totalBrands) {
			rewardCopy.validAt = ['All Brands'];
		} else {
			rewardCopy.validAt = rewardCopy.concepts.map((concept) => BrandsNames[concept]);
		}

		if (reward.concepts.includes(conceptId)) {
			result.redeemable.push(rewardCopy);
		} else {
			result.notRedeemable.push(rewardCopy);
		}
	});

	return result;
}

export const getRedeemableRewardsCount = (rewards: RewardWallet[], brand: Brands) => {
	const allRewards = getRewardsByBrand(rewards, brand);
	return allRewards.redeemable.length;
};

export const findOloReward = (equivalentRewards: RewardWallet[], responseRewards: Rewards[]) => {
	let rewardValue = 0;
	const oloReward = responseRewards.find((r) =>
		equivalentRewards?.find((reward) => {
			if (reward.id.toString() === r.externalreference.replace(/\D/g, '')) {
				rewardValue = reward.rewardValue;
				return reward;
			}
		})
	);
	return { oloReward, rewardValue };
};

export const validateAndUpdateBasket = async (basket: Basket, pageTitle: string) => {
	if (!basket) {
		return false;
	}

	const validationResponse = await CheckoutManager.ValidateBasket(basket.id);
	if (validationResponse.error) {
		GaEvent.ErrorMessage(validationResponse.error.message, pageTitle);
		return false;
	}

	const { retrieveBasket, error } = await CheckoutManager.GetBasket(basket.id);
	if (!retrieveBasket || error) {
		GaEvent.ErrorMessage(retrieveBasketError, pageTitle);
		return false;
	}

	retrieveBasket.customfields = basket.customfields;
	return retrieveBasket;
};
