import styled from 'styled-components';

export const Container = styled.div<{ headerHeight: number }>``;

export const LoggedUser = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ViewFullMenu = styled.div`
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding: 24px 24px 0;
	display: flex;
	justify-content: flex-start;

	@media screen and (min-width: 768px) {
		padding: 32px 32px 0;
	}
`;

export const MenuProducts = styled.div`
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding: 24px 24px 0;

	@media screen and (min-width: 768px) {
		padding: 32px 32px 0;
	}
`;

export const RecentAndFavesSection = styled.div`
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding: 0px 24px;

	@media screen and (min-width: 768px) {
		padding: 0px 32px;
	}
`;

export const BannerDiv = styled.div``;

export const InPersonMobile = styled.div`
	display: flex;
	background-image: url(${({ theme }) => theme.orderUi.images.bgRewardsBanner});
	height: 60px;
	padding: 8px 24px;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	gap: 16px;
	flex-shrink: 0;
`;

export const PromotionalBannerContainer = styled.div`
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding: 24px 24px 0;

	@media screen and (min-width: 768px) {
		padding: 32px 32px 0;
	}
`;
