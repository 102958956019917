import { FC } from 'react';
import { IconApp } from '@maverick/icons/dist/IconApp';
import { InfoIcon } from '@maverick/icons/dist/InfoIcon';
import { Icon } from '@maverick/ui';
import { useTheme } from 'styled-components';

import * as Styled from './WarningMessage.styled';

interface WarningMessageProps {
	message: string | JSX.Element;
	variant: 'info' | 'app' | 'points';
	displayStyle?: 'compact' | 'full' | 'app' | 'pointsReach' | 'pointsEarn';
}

export const WarningMessage: FC<WarningMessageProps> = ({ message, variant, displayStyle = 'full' }) => {
	const { orderUi } = useTheme();
	let icon: JSX.Element = <></>;
	let messageInfo: JSX.Element = <></>;
	let displayStl = displayStyle;

	switch (variant) {
		case 'info':
			icon = InfoIcon;
			displayStl = displayStyle;
			messageInfo = <Styled.Message>{message}</Styled.Message>;
			break;
		case 'app':
			icon = IconApp;
			displayStl = variant;
			messageInfo = <Styled.Message variant='app'>{message}</Styled.Message>;
			break;
		case 'points':
			icon = orderUi.icons.iconDollarSign;
			displayStl = displayStyle;
			if (displayStyle === 'pointsReach') {
				messageInfo = (
					<Styled.Message variant='points'>
						You could reach <strong>{message} points</strong> if you place this order
					</Styled.Message>
				);
			} else {
				messageInfo = (
					<Styled.Message variant='points'>
						You could earn <strong>{message} points</strong> if you sign in & order
					</Styled.Message>
				);
			}
		default:
			break;
	}

	return (
		<Styled.MessageSection
			displayStyle={variant == 'app' ? variant : displayStyle}
			data-testid={`${variant}-warning-message`}
		>
			<Styled.InfoIcon variant={variant}>
				<Icon icon={icon} ariaHidden={true} />
			</Styled.InfoIcon>
			<label tabIndex={0}>{messageInfo}</label>
		</Styled.MessageSection>
	);
};
