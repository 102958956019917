import { Dispatch } from 'redux';
import { UserStore, userSlice } from './User.slice';

export const UserOperations = {
	setUserRewards: (rewardsInfo: UserStore['userRewards']) => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.setUserRewards(rewardsInfo));
	},

	setUserRedeemableRewards: (redeemableRewardsInfo: UserStore['userRedeemableRewards']) => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.setUserRedeemableRewards(redeemableRewardsInfo));
	},

	setUserRecentOrders: (recentOrders: UserStore['userRecentOrders']) => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.setUserRecentOrders(recentOrders));
	},

	setUserFaves: (faves: UserStore['userFaves']) => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.setUserFaves(faves));
	},

	setUserFaveProducts: (faveProducts: UserStore['userFaveProducts']) => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.setUserFaveProducts(faveProducts));
	},

	setUserInfo: (userInfo: UserStore['userInfo']) => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.setUserInfo(userInfo));
	},

	setUserLogged: (setUserLogged: UserStore['userLogged']) => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.setUserLogged(setUserLogged));
	},

	resetUser: () => (dispatch: Dispatch) => {
		dispatch(userSlice.actions.resetUser());
	},
};
