import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const ProgressItems = styled.div<{}>`
	flex-direction: row;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;

	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		justify-content: flex-start;
	}
`;

export const Columns = styled.div`
	display: flex;
	flex: row;
	width: 100%;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding-left: 32px;
	}
`;

export const RewardsInfo = styled.div`
	${({ theme }) => theme.orderUi.typography.inputs.button}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	white-space: nowrap;

	& > span {
		color: ${({ theme }) => theme.orderUi.colors.primary.variant};
	}
`;

export const Points = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.caption}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	align-self: flex-end;
	margin-top: 8px;

	& > span {
		${({ theme }) => theme.orderUi.typography.title.subtitle}
	}
`;

export const ProgressContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px 0;
`;
