import { Option, OptionGroup } from '@maverick/entity';
import { Checkbox, useModal } from '@maverick/ui';
import { FC, useEffect } from 'react';
import { OptionsCheckboxListReduxProps } from '.';
import { OptionGroupSection } from '../OptionGroupSection';

import * as Styled from './OptionsCheckboxList.styled';
import { getOptionContext } from '../../../features/Menu/redux/Menu.slice';

interface OptionsCheckboxListProps extends OptionsCheckboxListReduxProps {
	options: Array<Option>;
	parentOption: OptionGroup;
	isMultiSelect: boolean;
	optionsGrid: number;
	isUpdate: boolean;
	isAppOnly?: boolean;
	setTransferBasketModal?: () => void;
}
export const OptionsCheckboxList: FC<OptionsCheckboxListProps> = ({
	options,
	parentOption,
	isMultiSelect,
	optionsGrid,
	isUpdate,
	selectedOptions,
	selectOption,
	rawOptionGroups,
	isAppOnly = false,
	setTransferBasketModal,
}) => {
	const { setModal } = useModal();
	const optionsSelected = options.filter((o) => selectedOptions.includes(o.id));
	const maxAllowedSelections = Number(parentOption.maxselects);
	const isMaxiumSelected = !!maxAllowedSelections && optionsSelected.length >= maxAllowedSelections;

	useEffect(() => {
		const hasSelectedOption = options.some((o) => selectedOptions.includes(o.id));
		if (!hasSelectedOption) {
			const defaultOption = options.find((o) => o.isdefault);
			if (defaultOption) {
				selectOption(defaultOption.id);
			}
		}
	}, []);

	const handleSelectOption = (id: number) => {
		if (isUpdate) {
			const context = getOptionContext(rawOptionGroups, id);
			if (context && OptionGroup.IsMasterOptionGroup(context.optionGroup)) {
				setModal({
					text: `Changing this item will reset other customizations for your item. Do you want to continue?`,
					primaryButton: {
						label: 'Change option',
						onClick: () => selectOption(id),
					},
					secondaryButton: {
						label: 'Cancel',
						onClick: () => {},
					},
					id: '',
				});
				return;
			}
		}
		if (isAppOnly && setTransferBasketModal) {
			setTransferBasketModal();
		}

		selectOption(id);
	};

	const retrieveOptionPriceLabel = (option: Option) => {
		if (option.cost) {
			return (
				<>
					{option.name}
					<strong>{` (+$${option.cost.toFixed(2)})`}</strong>
				</>
			);
		}
		return undefined;
	};

	return (
		<Styled.Container id='options-checkbox' data-testid='options-checkbox'>
			<Styled.Main columns={optionsGrid}>
				{options.map((o) => {
					const checked = selectedOptions.includes(o.id);
					return (
						<Checkbox
							id={o.id.toString()}
							key={o.id}
							label={o.name}
							htmlLabel={retrieveOptionPriceLabel(o)}
							checked={checked}
							onClick={() => handleSelectOption(o.id)}
							radio={!isMultiSelect}
							disabled={isMaxiumSelected}
						/>
					);
				})}
			</Styled.Main>

			{optionsSelected.map((o) => (
				<Styled.OptionGroupWrapper key={o.id}>
					{o.modifiers?.map((m) => (
						<OptionGroupSection
							optionGroup={m}
							isUpdate={isUpdate}
							key={m.id}
							isNested
							aria-labelledby={`option-group-label-${m}-${m.id}`}
						/>
					))}
				</Styled.OptionGroupWrapper>
			))}
		</Styled.Container>
	);
};
