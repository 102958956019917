import styled, { css } from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../../utils/GeneralUtils';
import { RestaurantStatus } from '../../../enums/RestaurantStatus';

export const Container = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 2px 4px;
	width: 100%;
	border-radius: 2px;
	background: ${({ theme }) => theme.orderUi.colors.tertiary.light};
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: 80%;
	}
`;

export const IconContainer = styled.div<{ isClosed: string }>`
	max-width: 16px;

	${({ isClosed }) =>
		isClosed === 'closed' &&
		css`
			color: ${({ theme }) => theme.orderUi.colors.tertiary.medium};
		`}
`;

export const HandoffTitle = styled.span<{ isClosed: string }>`
	display: flex;
	column-gap: 4px;
	flex-wrap: wrap;
	${({ theme }) => theme.orderUi.typography.caption.caption};

	${({ isClosed }) =>
		isClosed === 'closed' &&
		css`
			color: ${({ theme }) => theme.orderUi.colors.tertiary.medium};
		`}

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		column-gap: 8px;
		${({ theme }) => theme.orderUi.typography.body.bodySmall};
	}
`;

export const Label = styled.span``;

export const Colon = styled.span`
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		display: none;
	}
`;

export const Hour = styled.span`
	@media screen and (max-width: ${DESKTOP_BREAKPOINT}px) {
		flex-basis: 100%;
		order: 3;
	}
`;

export const Dot = styled.b`
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	@media screen and (max-width: ${DESKTOP_BREAKPOINT}px) {
		display: none;
	}
`;

export const Tag = styled.span<{ statusTag: string }>`
	font-weight: 700;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	@media screen and (max-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.caption.caption};
	}

	${({ statusTag }) => css`
		${statusTag === RestaurantStatus.Open || statusTag === RestaurantStatus.OrderAhead
			? css`
					color: ${({ theme }) => theme.orderUi.colors.complementary.success};
			  `
			: ''}
		${statusTag === RestaurantStatus.ClosingSoon
			? css`
					color: #e87000;
			  `
			: ''}
		${statusTag === RestaurantStatus.Closed || statusTag === RestaurantStatus.CloseForDineIn || statusTag === 'Unavailable'
			? css`
					color: ${({ theme }) => theme.orderUi.colors.complementary.error};
			  `
			: ''}
	`}
`;
