import App, { AppProps, AppContext, AppInitialProps } from 'next/app';
import {
	FunctionalCookies,
	ToastProvider,
	globalStyles,
	Header,
	BonefishHeader,
	ModalProvider,
	RecaptchaProvider,
	OutbackHeader,
	SkipLink,
} from '@maverick/ui';
import { ThemeProvider, DefaultTheme, createGlobalStyle } from 'styled-components';
import { Auth0Provider } from '@auth0/auth0-react';
import { Config } from '../src/Config';
import { Provider } from 'react-redux';
import { HomeLogo, NavigationProps, Brands } from '@maverick/entity';
import { AppManager } from '../src/features/App/App.manager';
import Link from 'next/link';
import { HeaderInteraction } from '../src/shared/components/HeaderInteraction';
import { BrowserRouter } from 'react-router-dom';
import Head from 'next/head';
import type { Router } from 'next/router';
import { WhitelabelSwitch } from '../src/shared/components';
import { Helmet } from 'react-helmet';
import { StoreWrapper } from '../src/infrastructure';
import { HomeProps } from './[[...path]]';
import { useState, useEffect } from 'react';
import 'radar-sdk-js/dist/radar.css';

const unslugify = (slug?: string): string | null => {
	if (!slug || !slug.length) return '';

	if (slug.includes('?')) slug = slug.split('?')[0];
	return slug
		.split('-')
		.map((word) => word[0].toUpperCase() + word.substring(1))
		.join(' ');
};

const getRestaurantName = (router: Router): string | null => {
	if (router.asPath.startsWith('/menu')) {
		const slug = router.asPath.split('/')[2];
		return unslugify(slug);
	}
	return null;
};

const GlobalStyles = createGlobalStyle`${globalStyles}`;

type MyAppProps = {
	routes: NavigationProps;
	logo: HomeLogo;
	theme: DefaultTheme;
};

const MyApp = ({
	Component,
	pageProps,
	routes,
	logo,
	router,
	allowDelivery,
	featureFlagItems,
	featureHighlight,
	theme,
}: AppProps & MyAppProps & HomeProps) => {
	const { store } = StoreWrapper.useWrappedStore(pageProps);
	const [isServer, setIsServer] = useState<boolean>(true);

	useEffect(() => {
		setIsServer(false);
	}, []);

	const restaurantName = getRestaurantName(router) ?? theme.orderUi.app.name;

	const ThemeGlobals = createGlobalStyle`${theme.orderUi.global}`;
	const FontFaces = createGlobalStyle`${theme.orderUi.fontFaces}`;

	return (
		<>
			<Head>
				<theme.orderUi.fontImports />
			</Head>

			<Helmet>
				<title>{`${restaurantName} - Order Online`}</title>
				<meta name='description' content={`Order Online at ${restaurantName}. Pay Ahead and Skip the Line.`} />
			</Helmet>

			{!isServer && (
				<>
					<WhitelabelSwitch />
					<FunctionalCookies />
					<GlobalStyles />

					<ThemeGlobals />
					<FontFaces />

					<BrowserRouter basename='/order'>
						<Provider store={store}>
							<ThemeProvider theme={theme}>
								<ToastProvider>
									<ModalProvider>
										<RecaptchaProvider>
											<Auth0Provider
												domain={Config.Auth0.domain}
												clientId={Config.Auth0.clientId}
												redirectUri={Config.Auth0.redirectUri}
												audience={Config.Auth0.audience}
												useRefreshTokens
												cacheLocation='localstorage'
												brand={Config.Brand}
											>
												<SkipLink
													text={'SKIP TO MAIN CONTENT'}
													link={'main-content'}
												></SkipLink>

												{Config.JssApp === Brands.Bonefish && (
													<BonefishHeader
														items={routes}
														linkComponent={Link}
														extra={<HeaderInteraction />}
														img={
															<img
																src={logo?.item?.Logo?.src ?? ''}
																alt={logo?.item?.Logo?.alt ?? ''}
															/>
														}
														imgMobile={
															<img
																src={logo?.item?.LogoMobile?.src ?? ''}
																alt={logo?.item?.LogoMobile?.alt ?? ''}
															/>
														}
														isOrder
														allowDelivery={allowDelivery ?? undefined}
													/>
												)}
												{Config.JssApp === Brands.Outback && (
													<OutbackHeader
														allowDelivery={allowDelivery}
														items={routes}
														linkComponent={Link}
														extra={<HeaderInteraction transformTooltip />}
														img={
															<img
																src={logo?.item?.Logo?.src ?? ''}
																alt={logo?.item?.Logo?.alt ?? ''}
															/>
														}
														imgMobile={
															<img
																src={logo?.item?.LogoMobile?.src ?? ''}
																alt={logo?.item?.LogoMobile?.alt ?? ''}
															/>
														}
														isOrder
													/>
												)}
												{Config.JssApp === Brands.Carrabbas && (
													<Header
														allowDelivery={allowDelivery}
														items={routes}
														linkComponent={Link}
														extra={<HeaderInteraction />}
														img={
															<img
																src={logo?.item?.Logo?.src ?? ''}
																alt={logo?.item?.Logo?.alt ?? ''}
															/>
														}
														imgMobile={
															<img
																src={logo?.item?.LogoMobile?.src ?? ''}
																alt={logo?.item?.LogoMobile?.alt ?? ''}
															/>
														}
														isOrder
													/>
												)}

												<Component
													{...pageProps}
													allowDelivery={allowDelivery}
													featureFlagItems={featureFlagItems}
													featureHighlight={featureHighlight}
												/>
											</Auth0Provider>
										</RecaptchaProvider>
									</ModalProvider>
								</ToastProvider>
							</ThemeProvider>
						</Provider>
					</BrowserRouter>
				</>
			)}
		</>
	);
};

const getTheme = async (): Promise<DefaultTheme> => {
	switch (Config.Brand) {
		case Brands.Outback:
			return (await import('@maverick/themes')).OutbackTheme;
		case Brands.Carrabbas:
			return (await import('@maverick/themes')).CarrabbasTheme;
		case Brands.Bonefish:
			return (await import('@maverick/themes')).BonefishgrillTheme;
		default:
			return (await import('@maverick/themes')).OutbackTheme;
	}
};

MyApp.getInitialProps = async (context: AppContext): Promise<AppInitialProps & MyAppProps & HomeProps> => {
	const ctx = await App.getInitialProps(context);

	const [routes, logo, allowDelivery, featureFlagItems, featureHighlight, theme] = await Promise.all([
		AppManager.GetRoutes(),
		AppManager.GetLogo(),
		AppManager.GetAllowDelivery(),
		AppManager.GetFeatureFlag(),
		AppManager.GetFeatureHighlight(),
		getTheme(),
	]);

	return { ...ctx, routes, logo, allowDelivery, featureFlagItems, featureHighlight, theme };
};

export default MyApp;
