import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import * as Styled from './CategoryHeader.styled';

interface CategoryHeaderProps {
	id: number;
	text: string;
}
export const CategoryHeader: FC<CategoryHeaderProps> = ({ id, text }) => {
	const { restaurantSlug } = useParams();
	if (!restaurantSlug) return null;

	const link = `/menu/${restaurantSlug}/categories/${id}`;
	return (
		<Styled.Container data-testid={`section-title-${text}`}>
			<Styled.Title>
				<Link tabIndex={0} to={link} title={text}> 
					{text}
				</Link>
			</Styled.Title>
		</Styled.Container>
	);
};
