import { FC, useEffect, ReactNode, useState } from 'react';
import * as Styled from './MenuContent.styled';
import { useTheme } from 'styled-components';
import { MenuMargins } from '../RestaurantMenu';
import { Button } from '@maverick/ui';
import { useRedirect } from '../../../../../shared/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ReduxProps } from '.';
import { DESKTOP_BREAKPOINT, GaEvent } from '@maverick/utils';
import { Brands, Category, PromotionalBannerData, UserRewards } from '@maverick/entity';
import { RecentAndFaves } from '../../RecentAndFaves';
import { useIsMobile } from '@maverick/hooks';
import { RewardsProgressColumn, RewardsRedeemColumn, RewardsInPersonColumn } from '../../../../../shared/components';
import { RewardsBanner } from '../../../../../shared/components/RewardsBanner';
import { RewardsMobile } from '../../../../../shared/components/RewardsBanner/RewardsMobile';
import { UserManager } from '../../../../User/User.manager';
import { ReturnIcon } from '@maverick/icons/dist/ReturnIcon';
import { RewardsWelcomeColumn } from '../../../../../shared/components/RewardsBanner/RewardsWelcomeColumn';
import { PromotionalBannerRendering } from '../../../../../shared/components/PromotionalBanner';
import { retrievePromotionalBanner } from '../../../../../utils/PromotionalBannerUtils';
import { PromotionalBanners } from '../../../../../shared/constants';
import { BannerHandoffStatus } from '../../../../../shared/components/BannerHandoffStatus';
import { getRedeemableRewardsCount } from '../../../../../utils/RewardsUtils';

export interface MenuContentProps extends ReduxProps {
	margins: MenuMargins;
	fullMenu?: boolean;
	children: ReactNode;
}

export const MenuContent: FC<MenuContentProps> = ({
	margins,
	fullMenu,
	recentOrdersAndFaves,
	redeemInPerson,
	userRewards,
	userInfo,
	faves,
	recentOrders,
	children,
	setRedirectRoute,
	setUserRewards,
	setSelectedProduct,
	highlightFeature,
	menu,
}) => {
	const { orderUi } = useTheme();
	const redirect = useRedirect();
	const { restaurantSlug } = useParams();
	const { isAuthenticated, user } = useAuth0();
	const location = useLocation();
	const isMobile = useIsMobile(DESKTOP_BREAKPOINT);
	const [promotionalBanner, setPromotionalBanner] = useState<PromotionalBannerData | null>(null);
	const [rewardsCount, setRewardsCount] = useState<number>(0);

	const redirectToMenu = () => {
		redirect(`/menu/${restaurantSlug}`);
	};

	const showRecentOrdersAndFaves = !!isAuthenticated && !!recentOrdersAndFaves?.active;
	const showRedeemInPersonMobile = !!isAuthenticated && !!redeemInPerson?.active;

	useEffect(() => {
		if (userRewards) {
			const currentBrand = orderUi.app.brand as unknown as Brands;
			const rewardsCount = getRedeemableRewardsCount(userRewards.rewardsWallets, currentBrand);
			setRewardsCount(rewardsCount);
		} else {
			setRewardsCount(0);
		}
	}, [userRewards]);

	useEffect(() => {
		if (isAuthenticated && user?.email) {
			if (recentOrdersAndFaves?.active) {
				GaEvent.Global.Set('ab_testing', 'recentAndFaves');
				GaEvent.FavoritesButtonImpression(faves?.length || 0);
				GaEvent.RecentOrdersButtonImpression(recentOrders?.length || 0);
			} else {
				GaEvent.Global.Set('ab_testing', null);
			}
		}
	}, [isAuthenticated, recentOrdersAndFaves?.active]);

	useEffect(() => {
		const retrieveHistory = async () => {
			if (isAuthenticated && user?.email && !userRewards) {
				const history = await UserManager.GetTransactionsHistoryByEmail(user.email);
				if (history?.transactionsHistory) {
					const userRewards = UserRewards.Make(history.transactionsHistory);
					setUserRewards(userRewards);
				}
			}
		};

		retrieveHistory();
	}, []);

	useEffect(() => {
		const handleProductBanner = async (bannerData: PromotionalBannerData) => {
			menu?.categories.find((category: Category) => {
				const foundProduct = category.products.find((product) => {
					return product.chainproductid == bannerData.linkTypeValue.value;
				});
				if (foundProduct) {
					return setPromotionalBanner(bannerData);
				}
			});
		};

		const handleCategoryBanner = async (bannerData: PromotionalBannerData) => {
			const foundCategory = menu?.categories.find(
				(category: Category) => category.id === bannerData.linkTypeValue.value
			);
			if (foundCategory) {
				setPromotionalBanner(bannerData);
			}
		};

		const fetchAndHandleBanner = async () => {
			const response = await retrievePromotionalBanner(PromotionalBanners.Menu.name);
			const bannerData = response?.bannerData;
			if (bannerData) {
				if (bannerData.linkType.value === 'Product') {
					handleProductBanner(bannerData);
				}
				if (bannerData.linkType.value === 'Category') {
					handleCategoryBanner(bannerData);
				}
				setPromotionalBanner(bannerData);
			}
		};

		fetchAndHandleBanner();
		setSelectedProduct(null);
	}, []);

	const handleBannerRedirect = () => {
		setRedirectRoute(location.pathname);
	};

	const showMobileBanner = !!fullMenu && isMobile && isAuthenticated && !!userRewards;

	const renderPromotionalBanner = () => {
		if (!!promotionalBanner) {
			return (
				<Styled.PromotionalBannerContainer>
					<PromotionalBannerRendering
						{...promotionalBanner}
						bannerInfo={PromotionalBanners.Menu}
						categoryList={menu?.categories}
						restaurantSlug={restaurantSlug}
					/>
				</Styled.PromotionalBannerContainer>
			);
		}
		return null;
	};

	const renderRecentAndFavesSection = (show: boolean) => {
		if (show) {
			return (
				<Styled.RecentAndFavesSection>
					<RecentAndFaves isAuthenticated={isAuthenticated} />
				</Styled.RecentAndFavesSection>
			);
		}
		return null;
	};

	const renderRewardsBanner = () => {
		if (userRewards) {
			return (
				<RewardsBanner hideWhenMobile hideReturnButton>
					<RewardsWelcomeColumn
						message={`${orderUi.texts.DineRewardsSalutation}, ${userInfo?.firstName}!`}
						rewardsCounter={rewardsCount}
						handleClick={() => {
							handleBannerRedirect();
							redirect('/my-rewards');
						}}
					/>

					{redeemInPerson?.active && <RewardsInPersonColumn highlightFeature={highlightFeature} />}
					<RewardsRedeemColumn rewards={userRewards} handleMenuRedirect={handleBannerRedirect} />
					<RewardsProgressColumn rewards={userRewards} key='progress' />
				</RewardsBanner>
			);
		}
		return null;
	};

	return (
		<Styled.Container headerHeight={margins.header}>
			{!fullMenu && (
				<Styled.ViewFullMenu>
					<Button
						text='View full Menu'
						variant='terciary'
						icon={ReturnIcon}
						revertIcon
						onClick={redirectToMenu}
					/>
				</Styled.ViewFullMenu>
			)}
			{fullMenu && isAuthenticated && (
				<Styled.LoggedUser>
					{renderRewardsBanner()}

					{showMobileBanner && showRedeemInPersonMobile && (
						<Styled.InPersonMobile id='mobile-rewards-in-person'>
							<RewardsInPersonColumn highlightFeature={highlightFeature} />
						</Styled.InPersonMobile>
					)}

					{renderRecentAndFavesSection(showRecentOrdersAndFaves)}
				</Styled.LoggedUser>
			)}

			{BannerHandoffStatus && <BannerHandoffStatus variant='menu' />}

			{renderPromotionalBanner()}
			{renderRecentAndFavesSection(!isAuthenticated)}

			<Styled.MenuProducts>{children}</Styled.MenuProducts>

			{showMobileBanner && (
				<RewardsMobile>
					<RewardsRedeemColumn rewards={userRewards} handleMenuRedirect={handleBannerRedirect} key='redeem' />
				</RewardsMobile>
			)}
		</Styled.Container>
	);
};
