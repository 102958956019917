import { connect, ConnectedProps } from 'react-redux';
import { AppOperations } from '../../../features/App/redux/App.operations';
import { DRBanner } from './DRBanner';
import { RootState } from '../../../infrastructure';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
	setRedirectRoute: AppOperations.setRedirectRoute,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const DRBannerConnected = connector(DRBanner);
export { DRBannerConnected as DRBanner };
