import { Option, Upsell } from '@maverick/entity';
import { Icon, ProductImage } from '@maverick/ui';
import React, { FC } from 'react';
import { handleKeyDown } from '../../../utils/GeneralUtils';
import * as Styled from './OptionsCard.styled';
import { useTheme } from 'styled-components';
export interface OptionsCardProps {
	option?: Option;
	upsell?: Upsell;
	onClick?: () => void;
	selected: boolean;
	testId?: string;
	imageBasePath?: string | undefined;
}

export const OptionsCard: FC<OptionsCardProps> = ({ option, upsell, onClick = () => {}, selected = false, testId, imageBasePath }) => {
	const theme = useTheme();
	let image: string | undefined = '';
	let name: string = '';
	let cost: string = '';
	if (!!option) {
		const optionImage = option?.images?.find((i) => i.groupname === 'mobileweb-modifier-choice')?.filename;
		if (optionImage) {
			image = imageBasePath + optionImage;
		} else {
			image = option.metadata?.find((i) => i.key === 'imageUrl')?.value;
		}

		name = option.name;
		cost = `$${option.cost.toFixed(2)}`;
	}
	if (!!upsell) {
		image = upsell.image;
		name = upsell.name;
		cost = upsell.cost;
	}

	const hasCost = () => {
		if (!!upsell) {
			const upsellCost = upsell.cost.replace('$', '');
			const parsedUpsellCost = parseFloat(upsellCost);

			return parsedUpsellCost > 0;
		}
		if (!!option) {
			return option.cost > 0;
		}

		return false;
	};

	return (
		<Styled.Container
			onClick={onClick}
			onKeyDown={(ev) => handleKeyDown(ev, onClick)}
			selected={selected}
			data-testid={`container-${testId ?? 'option-card'}`}
			tabIndex={0}
			aria-label={`${name} ${cost}`}
			role='option'
			aria-selected={selected}
		>
			<Styled.Content data-testid={testId ?? 'option-card'}>
				<Styled.ImageContainer>
					<span aria-hidden='true'>
						<ProductImage src={image} alt={name} />
					</span>
				</Styled.ImageContainer>
				<Styled.Description data-testid='option-name'>{name}</Styled.Description>
			</Styled.Content>

			<Styled.Cost data-testid='option-cost'>{hasCost() ? cost : ''}</Styled.Cost>

			<Styled.IconSelected>
				<Icon icon={theme.orderUi.icons.iconSelectedCard} customSize />
			</Styled.IconSelected>
		</Styled.Container>
	);
};
