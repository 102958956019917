import { FC } from 'react';
import * as Styled from './RewardsInPersonColumn.styled';
import { IconInPerson } from '@maverick/icons/dist/IconInPerson';
import { Button, Icon } from '@maverick/ui';
import { useTheme } from 'styled-components';
import { handleTagNew, redeemInPersonClick } from '../../../../utils/GeneralUtils';
import { Tooltip } from '../../Tooltip';
import { Tag } from '../../Tag';
import { FeatureFlagHighlight } from '@maverick/entity';

interface RewardsInPersonColumnProps {
	highlightFeature?: FeatureFlagHighlight;
}

export const RewardsInPersonColumn: FC<RewardsInPersonColumnProps> = ({ highlightFeature }) => {
	const { orderUi } = useTheme();

	const handleBannerClick = () => {
		const deepLink = orderUi.links.appDeepLink;
		const appStoreAndroid = orderUi.links.appStoreAndroid;
		const appStoreIos = orderUi.links.appStoreIos;

		redeemInPersonClick('Menu', deepLink, appStoreAndroid, appStoreIos, highlightFeature);
	};

	return (
		<Styled.InPersonColumn data-testid='rewards-in-person-column'>
			{handleTagNew(highlightFeature) && (
				<Tooltip
					description='Click here to open or install the app and easily redeem your rewards in restaurant.'
					id='redeem-in-person-tooltip'
					tabIndex={0}
				>
					<Tag variant='feature' text='New!' />
				</Tooltip>
			)}
			<Styled.ButtonRewardsInPerson>
				<Button
					text='Redeem reward in-person via App'
					variant='terciary'
					onClick={handleBannerClick}
					id='redeem-in-person'
					variantSize='buttonMedium'
				/>
			</Styled.ButtonRewardsInPerson>
			<Styled.Icon onClick={handleBannerClick}>
				<Icon icon={IconInPerson} customSize />
			</Styled.Icon>
		</Styled.InPersonColumn>
	);
};
