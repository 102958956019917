import { FC, useEffect, useState, useRef } from 'react';
import { MenuManager } from '../../Menu.manager';
import { ReduxProps } from './index';
import { useInput, useIsMobile } from '@maverick/hooks';
import {
	Button,
	useToast,
	Input,
	ProductImage,
	SkeletonCustomizeProduct,
	Icon,
	useModal,
	AnimatedIcon,
} from '@maverick/ui';
import { Basket, BasketProduct, Choice, HandoffMethods, Menu, Option } from '@maverick/entity';
import { OptionGroupSection } from '../../../../shared/components';
import { useRedirect } from '../../../../shared/hooks';
import { addUpsellItems, getProductOptionsError, itemAppOnlyDownloadApp } from '../../../../shared/constants';
import { NutritionalInformation } from '../../../../shared/components/NutritionalInformation';
import { DESKTOP_BREAKPOINT, DateWithoutUTCFormat, GaEvent, UtilsIsAppOnly, Storage } from '@maverick/utils';
import { orderAppOnlyProduct } from '../../../../utils/GeneralUtils';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './CustomizeProduct.styled';
import { Helmet } from 'react-helmet';
import { ReturnIcon } from '@maverick/icons/dist/ReturnIcon';
import { getOptionContext, normalizeAllOptions } from '../../redux/Menu.slice';
import { CheckoutManager } from '../../../Checkout/Checkout.manager';
import { useAuth0 } from '@auth0/auth0-react';
import { WarningMessage } from '../../../../shared/components/WarningMessage';
import { FavoriteFilledIcon } from '@maverick/icons/dist/FavoriteFilledIcon';
import { FavoriteIcon } from '@maverick/icons/dist/FavoriteIcon';
import { Config } from '../../../../Config';
import { BannerHandoffStatus } from '../../../../shared/components/BannerHandoffStatus';

export interface CustomizeProductProps extends ReduxProps {}
export const CustomizeProduct: FC<CustomizeProductProps> = ({
	selectedProduct,
	optionGroups,
	selectedOptions,
	loading,
	processing,
	imageBasePath,
	basket,
	editedProduct,
	menu,
	deliveryAddress,
	selectedRestaurant,
	rawOptionGroups,
	redirectRouteMenu,
	skeletonPages,
	userToken,
	method,
	authToken,
	userInfo,
	addCartOrigin,
	setLoading,
	setProcessing,
	setOptionGroups,
	setBasket,
	setRedirectRouteEdit,
	setSelectedProduct,
	setEditedProduct,
	setEditedOptions,
	resetCustomize,
	setBasketCreationDate,
	setDeliveryAddress,
	setMethod,
	setRedirectRouteMenu,
	setAddCartOrigin,
}) => {
	const [totalCost, setTotalCost] = useState<number | undefined>(selectedProduct?.cost);
	const { setToast } = useToast();
	const redirect = useRedirect();
	const location = useLocation();
	const { orderUi } = useTheme();
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const { setModal } = useModal();

	const baseUrl = String(process.env.ORDER_URL);
	const productHasCalories = !!selectedProduct?.basecalories;

	const { restaurantSlug, productId, basketProductId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();

	const isMobile = useIsMobile(DESKTOP_BREAKPOINT);

	const isProductUpdate = !!basketProductId;
	const isAppOnly = (menu && UtilsIsAppOnly(menu, Number(productId))) ?? undefined;
	const [renderFinished, SetRenderFinished] = useState<boolean>(false);
	const [showedIcon, setShowedIcon] = useState<JSX.Element>(<Icon icon={FavoriteIcon} customSize />);

	const currentProductCategory = menu?.categories.find((c) => c.products.find((p) => p.id === selectedProduct?.id));

	const [showChainProductId, setShowChainProductId] = useState<boolean>(false);

	useEffect(() => {
		const URLDeliveryAddress = searchParams.get('deliveryAddress') ?? '';
		const redirectFromCMS = searchParams.get('redirectFromCMS');
		const hasCMSParams = URLDeliveryAddress || redirectFromCMS;
		const enableChainProductId = searchParams.get('enableChainProductId');

		if (enableChainProductId === 'true') {
			setShowChainProductId(true);
		}

		if (hasCMSParams) {
			if (isBasketDifferent(URLDeliveryAddress)) {
				setModal({
					text: 'You already have items in your basket from a different restaurant or order type.',
					primaryButton: {
						label: 'Continue with new basket',
						onClick: () => handleNewBasketClick(URLDeliveryAddress),
					},
					secondaryButton: {
						label: 'Check order preferences',
						onClick: () => returnToSelectLocation(),
					},
					id: 'warning-transfer-basket-modal',
				});
			} else {
				handleNewBasketClick(URLDeliveryAddress);
			}
		}
	}, [searchParams]);

	useEffect(() => {
		setLoading(true);

		if (!menu) {
			setLoading(false);
			redirect(`/menu/${restaurantSlug}`);
			return;
		}

		if (productId) {
			renderProductDetails(productId, '', menu, basket);
		} else if (basketProductId) {
			const productId = basket?.products.find((p) => p.id === +basketProductId)?.productId.toString();
			if (productId) {
				renderProductDetails(productId, basketProductId, menu, basket);
			} else {
				setLoading(false);
				redirect(`/menu/${restaurantSlug}`);
			}
		}
		return () => resetCustomize();
	}, [productId, basketProductId]);

	useEffect(() => {
		if (!selectedProduct) return;
		initCustomizeIt();
		getAmount();
	}, [selectedProduct]);

	useEffect(() => {
		if (!selectedProduct) return;
		getAmount();
	}, [selectedOptions, optionGroups]);

	useEffect(() => {
		if (selectedProduct && renderFinished) {
			const isVariable = rawOptionGroups.some((r) => {
				return !!r.options.find((o) => o.cost > 0);
			});

			if (currentProductCategory && !!basket) {
				GaEvent.ViewItem(
					selectedProduct,
					1,
					basket.vendorid,
					selectedRestaurant!.brand,
					currentProductCategory.name,
					totalCost ?? selectedProduct.cost,
					isVariable
				);
			}
		}
	}, [rawOptionGroups]);

	const isBasketDifferent = (URLDeliveryAddress: string) => {
		const deliveryMethod = URLDeliveryAddress ? ['delivery'] : ['curbside', 'pickup'];
		return (
			basket && (basket?.vendorid !== selectedRestaurant?.id || !deliveryMethod.includes(basket?.deliverymode))
		);
	};

	const handleNewBasketClick = (URLDeliveryAddress: string) => {
		setRedirectRouteMenu(null);
		if (isBasketDifferent(URLDeliveryAddress)) {
			setBasket(null);
		}
		if (URLDeliveryAddress) {
			const decocedURLDeliveryAddress = JSON.parse(decodeURIComponent(URLDeliveryAddress));
			setDeliveryAddress(decocedURLDeliveryAddress);
			setMethod(HandoffMethods.Delivery);
			Storage.Session.Set('isDeliverySelected', true);
		} else {
			setMethod(HandoffMethods.Curbside);
		}
	};

	const returnToSelectLocation = () => {
		window.open(baseUrl, '_self');
	};

	const specialInstructions = useInput('', {
		id: 'special-instructions',
		label: 'Special instructions',
		placeholder: 'Additional preferences and dietary needs',
		maxlength: 40,
		charCounter: true,
	});

	const whosThisFor = useInput('', {
		id: 'item-made-for',
		label: 'Item made for',
		placeholder: 'Name of person receiving the item',
	});

	const initCustomizeIt = async () => {
		if (!selectedProduct) return;

		const result = await MenuManager.GetProductOptions(Number(selectedProduct.id));
		if (result.error || !result.optionGroup) {
			const errorMessage = result.error ?? getProductOptionsError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage);
			setLoading(false);
			return;
		}
		setOptionGroups(result.optionGroup);
		setLoading(false);
	};

	if (!selectedProduct) {
		return null;
	}

	const getAmount = () => {
		let amount = selectedProduct.cost;

		selectedOptions.forEach((i) => {
			const context = getOptionContext(optionGroups, i);
			if (!context) return;
			amount += context.option.cost;
		});

		setTotalCost(amount);
	};

	const updateSingleProductToBasket = async () => {
		setLoading(true);
		if (!basket) {
			setToast({
				variant: 'error',
				text: 'An error occurred! Product is not part of this basket',
			});
			setLoading(false);
			return;
		}

		if (!editedProduct) {
			setLoading(false);
			return;
		}

		const { response, error } = await MenuManager.UpdateSingleProductToBasket(
			basket.id,
			editedProduct.id,
			selectedProduct.id,
			editedProduct.quantity,
			selectedOptions.map((id) => String(id)),
			specialInstructions.value,
			whosThisFor.value
		);

		if (error || !response) {
			setToast({
				variant: 'error',
				text: 'An error occurred! ' + error,
			});
			GaEvent.ErrorMessage('An error occurred! ' + error);
			setLoading(false);
			return;
		}

		setBasket(response);
		setLoading(false);
		handleRedirectToMenu();
	};

	const initBasket = async () => {
		const restaurant = selectedRestaurant!;
		const noBasket = !basket;
		const differentRestaurant = !!basket && basket.vendorid !== restaurant.id;

		if (noBasket || differentRestaurant) {
			const { basket, error } = await CheckoutManager.SetBasket(
				restaurant.id,
				isAuthenticated ? userToken : null
			);

			if (error || !basket) {
				return null;
			}

			if (method === HandoffMethods.Delivery && deliveryAddress) {
				const { id, ...newDeliveryAddress } = deliveryAddress;
				const { settedAddressToBasket, setBasketDeliveryAddressError } =
					await CheckoutManager.SetBasketDeliveryAddress(basket.id, newDeliveryAddress);

				if (setBasketDeliveryAddressError || !settedAddressToBasket) {
					return null;
				}

				setBasket(settedAddressToBasket);
				setBasketCreationDate(DateWithoutUTCFormat(new Date()));
				return settedAddressToBasket;
			} else {
				setBasket(basket);
				setBasketCreationDate(DateWithoutUTCFormat(new Date()));
				return basket;
			}
		} else {
			if (basket.deliverymode !== method) {
				if (method === HandoffMethods.Delivery) {
					const { id, ...deliveryWithoutId } = deliveryAddress!;
					const { settedAddressToBasket, setBasketDeliveryAddressError } =
						await CheckoutManager.SetBasketDeliveryAddress(basket.id, deliveryWithoutId);
					if (setBasketDeliveryAddressError || !settedAddressToBasket) {
						return null;
					} else {
						setBasket(settedAddressToBasket);
					}
				} else {
					const { settedHandoffToBasket, setHandoffBasketError } =
						await CheckoutManager.SetBasketHandoffMethod(basket.id, method);
					if (setHandoffBasketError || !settedHandoffToBasket) {
						return null;
					} else {
						setBasket(settedHandoffToBasket);
					}
				}
			} else {
				return basket;
			}
		}
	};

	const addSingleProductToBasket = async () => {
		setProcessing(true);

		const basket = await initBasket();

		if (!basket) {
			setProcessing(false);
			return;
		}

		const { response, error } = await MenuManager.AddSingleProductToBasket(
			basket.id,
			selectedProduct.id,
			1,
			selectedOptions.map((o) => String(o)),
			specialInstructions.value,
			whosThisFor.value
		);

		if (error || !response) {
			setToast({
				variant: 'error',
				text: 'An error occurred! ' + error,
			});

			GaEvent.ErrorMessage('An error occurred! ' + error);
			setProcessing(false);
			if (error) {
				handleErrorAddToBasket(error);
			}
			return;
		}

		setBasket(response);

		setToast({
			variant: 'success',
			text: addUpsellItems,
		});

		const selectedProductCategory = menu?.categories.find((c) =>
			c.products.find((p) => p.id === selectedProduct?.id)
		);

		const isVariable = rawOptionGroups.some((r) => {
			return !!r.options.find((o) => selectedOptions?.includes(o.id) && o.cost > 0);
		});

		if (selectedProductCategory) {
			GaEvent.AddToCart(
				selectedProduct,
				1,
				selectedRestaurant!.id,
				selectedRestaurant!.brand,
				selectedProductCategory.name,
				totalCost!,
				addCartOrigin,
				isVariable
			);
		}

		setProcessing(false);
		setAddCartOrigin(' ');
		handleRedirectToMenu();
	};

	const getErrorField = (error: string) => {
		const errorField = error.match(/'(.*?)'/g)?.[0].replace(/[']/g, '');
		return errorField || '';
	};

	const findElementByTitle = (errorField: string) => {
		const groups = Array.from(document.querySelectorAll('.option-group'));
		for (const group of groups) {
			const titles = Array.from(group.querySelectorAll('h2'));
			for (const title of titles) {
				if (title.textContent === errorField) return group as HTMLElement;
			}
		}
		return null;
	};

	const highlightElement = (element: HTMLElement) => {
		element.tabIndex = 0;
		element.focus();
		element.style.padding = '8px';
		element.style.margin = '16px -8px -8px';
		element.style.backgroundColor = orderUi.colors.secondary.medium;
		setTimeout(() => {
			element.style.width = '';
			element.style.padding = '';
			element.style.margin = '';
			element.style.background = '';
		}, 3_000);
	};

	const handleErrorAddToBasket = (error: string) => {
		const errorField = getErrorField(error);
		const errorElementByTitle = findElementByTitle(errorField);
		if (errorElementByTitle) {
			highlightElement(errorElementByTitle);
			return;
		}

		for (const option of selectedOptions) {
			const context = getOptionContext(rawOptionGroups, option);
			if (!context || !context.option.modifiers) continue;

			for (const m of context.option.modifiers) {
				if (m.description !== errorField) continue;
				if (m.options.map((o) => o.id).some((r) => selectedOptions.includes(r))) continue;

				const optionGroup = findElementByTitle(context.optionGroup.description);
				if (!optionGroup) continue;

				const subtitles = Array.from(optionGroup.querySelectorAll('h3'));
				for (const subtitle of subtitles) {
					if (subtitle.textContent === errorField && subtitle.parentElement?.parentElement) {
						highlightElement(subtitle.parentElement.parentElement);
						break;
					}
				}
			}
		}
	};

	const validateRequiredOptions = (): boolean => {
		const requiredNotSelected = optionGroups.find(
			(item) =>
				(item.mandatory || (!item.mandatory && item.minselects && Number(item.minselects) > 0)) &&
				!item.options.some((option) => {
					return selectedOptions.includes(option.id);
				})
		);
		if (requiredNotSelected) {
			const errorElementByTitle = findElementByTitle(requiredNotSelected.description);
			if (errorElementByTitle) {
				highlightElement(errorElementByTitle);
				return false;
			}
		}
		return true;
	};

	function renderProductDetails(productId: string, basketProductId: string, menu: Menu, basket: Basket | null) {
		const currentProduct = menu?.categories
			.find((category) => category.products.find((product) => product.id === +productId))
			?.products.find((product) => product.id === +productId);

		if (!currentProduct) {
			setLoading(false);
			redirect(`/menu/${restaurantSlug}`);
			return;
		}

		setSelectedProduct(currentProduct);

		if (isProductUpdate) {
			const editedProduct = basket?.products.find((product) => product.id === +basketProductId);
			if (!editedProduct) return;
			setEditedProduct(editedProduct);
			setEditedOptions(editedProduct.choices.map((c) => c.optionid));
			specialInstructions.setValue(editedProduct.specialinstructions);
			whosThisFor.setValue(editedProduct.recipient);
			setRedirectRouteEdit(location.pathname);
		}
		SetRenderFinished(true);
		return;
	}

	const retrieveProductModifiers = async () => {
		const normalized = normalizeAllOptions(rawOptionGroups);
		const choices: Choice[] = [];
		let detailedOption: Option;
		selectedOptions.forEach((optionId, index) => {
			detailedOption = new Option();
			normalized.forEach((item) => {
				let opt = item.options.find((option) => {
					return option.id === optionId;
				});
				if (opt) {
					detailedOption = opt;
				}
			});
			if (detailedOption) {
				const newChoice = convertOptionToChoice(detailedOption, index);

				choices.push(newChoice);
			}
		});
		return choices;
	};

	const convertOptionToChoice = (option: Option, index: number) => {
		const choice: Choice = {
			cost: option.cost,
			id: 1,
			indent: 0,
			metric: index,
			name: option.name,
			customfields: [],
			optionid: option.id,
			quantity: 1,
			chainchoiceid: option.chainoptionid.toString(),
		};
		return choice;
	};

	const saveFavorite = async () => {
		if (!authToken) return;
		setProcessing(true);

		const choices = await retrieveProductModifiers();

		let savedName = `${userInfo?.firstName}'s ${selectedProduct.name}`;

		const { response } = await MenuManager.RetrieveFavoriteProduct(authToken);

		let count = 0;

		response?.savedproducts?.forEach((item: any) => {
			if (String(item.name).includes(savedName)) count++;
		});

		savedName = mountSavedName(savedName, count);

		const product = new BasketProduct({
			chainproductid: selectedProduct.chainproductid.toString(),
			recipient: whosThisFor.value,
			specialinstructions: specialInstructions.value,
			quantity: 1,
			choices: choices,
		});

		const { error } = await MenuManager.SaveFavoriteProduct(savedName, product, authToken);

		if (error) {
			setToast({
				variant: 'error',
				text: error,
			});
		} else {
			setToast({
				variant: 'success',
				text: 'Favorite item saved!',
			});
			setShowedIcon(
				<Styled.AnimatedIconContainer>
					<AnimatedIcon
						icon={orderUi.icons.iconFavoriteAnimation}
						onAnimationComplete={() =>
							setShowedIcon(<Icon icon={FavoriteFilledIcon} testId={'filledIcon'} customSize />)
						}
						testId={'animated-icon'}
					/>
				</Styled.AnimatedIconContainer>
			);
			if (currentProductCategory)
				GaEvent.SaveCustomizationSuccess(currentProductCategory.name, selectedProduct.name);
		}
		setProcessing(false);
	};

	const mountSavedName = (saveName: string, version: number) => {
		if (version === 0) return saveName;

		const versionText = ` ${version}`;
		const totalLenght = saveName.length + versionText.length;

		if (totalLenght <= 128) return saveName + versionText;

		return saveName.substring(0, saveName.length - (totalLenght - 128)) + versionText;
	};

	const handleRedirectToMenu = () => {
		redirect(redirectRouteMenu ?? `/menu/${restaurantSlug}`);
	};

	if (loading && skeletonPages?.active) {
		return <SkeletonCustomizeProduct />;
	}

	const image = selectedProduct.images?.find((image) => image.groupname === 'mobile-webapp-customize')?.filename;
	const imagePath = image ? `${imageBasePath}${image}` : '';

	const handleModalClick = () => {
		const deepLink = `${orderUi.app.brand}://order/menu?productId=${selectedProduct.chainproductid}&vendorId=${selectedRestaurant?.id}`;
		const appStoreAndroid = orderUi.links.appStoreAndroid;
		const appStoreIos = orderUi.links.appStoreIos;
		GaEvent.AppOnlyAddToCart(deepLink);

		orderAppOnlyProduct(deepLink, appStoreAndroid, appStoreIos);
	};

	const handleFavoriteSignInModalClick = () => {
		setLoading(true);
		loginWithRedirect({ source: Config.Source, prompt: 'login' });
	};

	const handleFavoriteJoinNowModalClick = () => {
		setLoading(true);
		loginWithRedirect({ source: Config.Source });
	};

	const handleFavoriteClick = () => {
		if (!isAuthenticated) {
			setModal({
				title: 'Join Dine Rewards',
				text: 'Sign in or join Dine Rewards now to save your favorites and quickly reorder.',
				primaryButton: {
					label: 'Sign in',
					onClick: handleFavoriteSignInModalClick,
				},
				secondaryButton: {
					label: 'Join now',
					onClick: handleFavoriteJoinNowModalClick,
				},
				id: 'sign-in-favorite-modal',
				hasCloseButton: true,
			});
		} else {
			if (validateRequiredOptions()) {
				saveFavorite();
			}
		}
		if (currentProductCategory) GaEvent.SaveCustomizationClick(currentProductCategory.name, selectedProduct.name);
	};

	function selectButton(): JSX.Element {
		if (isAppOnly) {
			if (optionGroups.length > 0) {
				return (
					<Button
						text={`Order in ${orderUi.app.shortName} App`}
						variant='primary'
						onClick={handleModalClick}
						id='apponly-item'
						loading={processing}
					/>
				);
			}
			return (
				<Button
					text={`Go to ${orderUi.app.shortName} App`}
					variant='primary'
					onClick={handleModalClick}
					id='apponly-item'
					loading={processing}
				/>
			);
		}
		if (isProductUpdate) {
			return (
				<Button
					text={`Update Item - $${totalCost?.toFixed(2)}`}
					variant='primary'
					onClick={updateSingleProductToBasket}
					id='update-item'
					loading={processing}
				/>
			);
		}
		return (
			<Button
				text={`Add to Cart - $${totalCost?.toFixed(2)}`}
				variant='primary'
				onClick={addSingleProductToBasket}
				id='add-to-cart'
				loading={processing}
			/>
		);
	}

	return (
		<Styled.Container data-testid='customize-page'>
			<Helmet>
				<title>{`${orderUi.app.name} - ${selectedProduct?.name} - Order Online`}</title>
				<link rel='canonical' href={`${baseUrl}/menu/${restaurantSlug}/products/${productId}`} />

				<meta property='og:title' content={`${selectedRestaurant!.name} - Order Online`}></meta>
				<meta property='og:type' content='restaurant'></meta>
				<meta property='og:image' content={orderUi.links.logoMeta}></meta>
				<meta property='og:url' content={window.location.href}></meta>
				<meta
					property='og:description'
					content={`Order Online at ${selectedRestaurant!.name}, ${
						selectedRestaurant!.city
					}. Pay Ahead and Skip the Line.`}
				></meta>
				<meta property='og:locale:alternate' content='en_US'></meta>
				<meta property='og:site_name' content={orderUi.app.name}></meta>
			</Helmet>
			<Styled.LeftContainer>
				<Styled.ProductImageContainer>
					<ProductImage src={imagePath} alt={selectedProduct.name} />
				</Styled.ProductImageContainer>

				<Styled.ProductCustomizeContainer>
					<Styled.Content>
						<Styled.ButtonReturn id='return-button'>
							<Button
								icon={ReturnIcon}
								text={isProductUpdate ? 'Return to Order Summary' : 'Return to Menu'}
								variant='terciary'
								id='main-content'
								revertIcon
								onClick={handleRedirectToMenu}
								variantSize='buttonMedium'
							/>
						</Styled.ButtonReturn>

						<Styled.ProductInfoContainer>
							<Styled.Title tabIndex={0}>
								{selectedProduct.name}
								{showChainProductId && ` - ${selectedProduct.chainproductid}`}{' '}
							</Styled.Title>
							<Styled.Description tabIndex={0}>{selectedProduct.description}</Styled.Description>
							<Styled.Calories tabIndex={0}>
								{productHasCalories && (
									<Styled.CaloriesText aria-hidden='true' data-testid='calories-info'>
										Starting at {selectedProduct.basecalories} calories
									</Styled.CaloriesText>
								)}
							</Styled.Calories>
						</Styled.ProductInfoContainer>

						<BannerHandoffStatus variant='product' />

						{isAppOnly && <WarningMessage variant='app' message={itemAppOnlyDownloadApp} />}

						<Styled.OptionGroupContainer aria-labelledby='option group container'>
							{optionGroups.map((group, index) => {
								return (
									<Styled.OptionGroupWrapper
										aria-labelledby={`option-group-label-${group.description}`}
										key={index}
									>
										<OptionGroupSection
											optionGroup={group}
											isUpdate={isProductUpdate}
											isAppOnly={isAppOnly}
											handleClick={handleModalClick}
										/>
									</Styled.OptionGroupWrapper>
								);
							})}
						</Styled.OptionGroupContainer>

						<Styled.OrderInformation>
							<Styled.Information>
								<Styled.InfoTitle data-testid='info-title'>Order Information</Styled.InfoTitle>
								<Input
									handler={specialInstructions}
									helperText={'No price altering substitutions/additions'}
								/>
								<Input
									handler={whosThisFor}
									onEnter={isProductUpdate ? updateSingleProductToBasket : addSingleProductToBasket}
								/>
							</Styled.Information>
						</Styled.OrderInformation>
					</Styled.Content>
				</Styled.ProductCustomizeContainer>

				{isMobile && (
					<>
						{orderUi.booleans.productImageTextureDetail && <Styled.TextureDetailMobile />}
						<Styled.NutritionalInformationMobile>
							<Styled.Content>
								<NutritionalInformation productName={selectedProduct.name} />
							</Styled.Content>
						</Styled.NutritionalInformationMobile>
					</>
				)}

				<Styled.ButtonWrapper>
					{
						<Styled.FavoriteButtonContainer
							onClick={handleFavoriteClick}
							data-testid='save-item-favorite-button'
						>
							<Styled.FavoriteButtonIcon>{showedIcon}</Styled.FavoriteButtonIcon>
							<Styled.FavoriteButtonText>Save item as favorite</Styled.FavoriteButtonText>
						</Styled.FavoriteButtonContainer>
					}
					<Styled.Button>{selectButton()}</Styled.Button>
				</Styled.ButtonWrapper>
			</Styled.LeftContainer>

			<Styled.RightContainer>
				<Styled.Align>
					<Styled.ImageDesktopContainer>
						<ProductImage src={imagePath} alt={selectedProduct.name} />

						{orderUi.booleans.productImageTextureDetail && <Styled.TextureDetail />}
					</Styled.ImageDesktopContainer>

					{!isMobile && (
						<>
							<Styled.NutritionalInformationDesktop>
								<NutritionalInformation productName={selectedProduct.name} />
							</Styled.NutritionalInformationDesktop>
						</>
					)}
				</Styled.Align>
			</Styled.RightContainer>
		</Styled.Container>
	);
};
