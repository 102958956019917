import styled from 'styled-components';

export const SignUpOptionSection = styled.div<{ displayStyle: 'compact' | 'full' | 'app' }>`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	${({ displayStyle }) => displayStyle === 'app' && 'align-items: center'};
	background-color: ${({ theme }) => theme.orderUi.colors.secondary.light};
	border-radius: 4px;
	${({ displayStyle }) => displayStyle === 'full' && 'margin-top: 24px;'};
	padding: 16px;
`;

export const TextContent = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: -1px;
`;

export const TitleContent = styled.div`
	font-weight: bold;
	margin-bottom: 12px;
	font-size: 16px;
`;

export const DescriptionContent = styled.div`
	max-height: 100px;
	line-height: 2;
	font-size: 14px;
	font-weight: 400;
`;
export const DescriptionItem = styled.div`
	display: flex;
	justify-content: start;
	padding: 6px 0;
	line-height: 1.5;
`;

export const DescriptionText = styled.div`
	margin-left: 8px;
`;

export const termsAndConditions = styled.div`
	margin-top: 24px;
	text-align: start;
	font-size: 14px;

	a {
		text-decoration: underline !important;
		color: ${({ theme }) => theme.orderUi.colors.primary.medium} !important;
		font-weight: bold;

		&:focus {
			outline: auto 2px ${({ theme }) => theme.orderUi.colors.neutral.dark};
		}
	}
`;
