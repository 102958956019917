import { Calendar, CalendarTypes, HandoffMethods, Restaurant } from '@maverick/entity';
import { isToday, format, differenceInMinutes, isAfter, isSameDay, isBefore } from 'date-fns';
import { RestaurantStatus } from '../shared/enums/RestaurantStatus';

interface CalendarInfo {
	type: CalendarTypes;
	range: string;
	tag: RestaurantStatus | undefined;
}

const getCalendars = (restaurant: Restaurant): Calendar[] => {
	const findType = (type: CalendarTypes) => restaurant.calendar.find((c) => c.type === type);
	const calendars = [];

	if (restaurant.supportscurbside) {
		calendars.push(findType(CalendarTypes.Curbside));
	} else if (restaurant.canpickup) {
		const pickupCalendar = findType(CalendarTypes.Pickup);
		if (!pickupCalendar && !restaurant.candeliver) {
			const businessCalendar = { ...findType(CalendarTypes.Business), type: CalendarTypes.Pickup };
			if (businessCalendar) calendars.push(businessCalendar);
		} else calendars.push(pickupCalendar);
	}

	if (restaurant.candeliver) calendars.push(findType(CalendarTypes.Delivery));

	calendars.push(findType(CalendarTypes.Business));

	return calendars.filter(Boolean) as Calendar[];
};

const formatTimeRange = (start: Date, end: Date): string => {
	const formatTime = (date: Date) => format(date, date.getMinutes() === 0 ? 'haaa' : 'h:mmaaa');
	return `${formatTime(start)} - ${formatTime(end)}`;
};

const getTag = (
	calendar: Calendar,
	todayRange: { start: Date; end: Date },
	restaurant: Restaurant
): RestaurantStatus | undefined => {
	const now = new Date();
	const { start, end } = todayRange;

	if (start <= now && now < end)
		return differenceInMinutes(end, now) < 60 ? RestaurantStatus.ClosingSoon : RestaurantStatus.Open;

	if (isBefore(now, start) && isSameDay(now, start)) {
		const isAdvanceOrderSupported = restaurant.supportedtimemodes.includes('advance');
		const isBusinessCalendar = calendar.type === CalendarTypes.Business;

		if (isAdvanceOrderSupported && !isBusinessCalendar) {
			return RestaurantStatus.OrderAhead;
		}
		return RestaurantStatus.Closed;
	} else if (isAfter(now, end)) return RestaurantStatus.Closed;
};

const getCalendarInfo = (calendar: Calendar, restaurant: Restaurant): CalendarInfo | undefined => {
	const todayRange = calendar.ranges?.find((r) => isToday(new Date(r.start)));
	if (!todayRange) return undefined;

	const { start, end } = todayRange;
	const range = formatTimeRange(start, end);
	const tag = getTag(calendar, todayRange, restaurant);

	return { type: calendar.type as CalendarTypes, range, tag };
};

const adjustBusinessCalendarTag = (response: CalendarInfo[]): void => {
	const businessCalendar = response.find((r) => r?.type === CalendarTypes.Business);
	if (businessCalendar && businessCalendar.tag === RestaurantStatus.Closed) {
		const isAnyOtherCalendarOpen = response.some(
			(r) => r?.tag !== RestaurantStatus.Closed && r?.type !== CalendarTypes.Business
		);
		if (isAnyOtherCalendarOpen) businessCalendar.tag = RestaurantStatus.CloseForDineIn;
	}
};

export const handleHours = (restaurant: Restaurant): CalendarInfo[] | 'Unavailable' => {
	if (!restaurant.isavailable) return 'Unavailable';

	const calendars = getCalendars(restaurant);
	const response = calendars
		.map((calendar) => getCalendarInfo(calendar, restaurant))
		.filter((info): info is CalendarInfo => info !== undefined);

	adjustBusinessCalendarTag(response);

	return response;
};

export const mapHandoffMethodToCalendarType = (handoffMethod: HandoffMethods): CalendarTypes => {
	switch (handoffMethod) {
		case HandoffMethods.Curbside:
			return CalendarTypes.Curbside;
		case HandoffMethods.Delivery:
			return CalendarTypes.Delivery;
		case HandoffMethods.Pickup:
			return CalendarTypes.Pickup;
		default:
			return CalendarTypes.None;
	}
};
